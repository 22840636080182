
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Grid, Icon, Menu, Dropdown } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/ToolSelectComponent"
import UnitInput from '../Basics/BasicUnitInput';
import LocalizedStrings_ObjectProperties from "../../localization/LightRightObjectsFields";

import SelectResourceModal from "./SelectRessourceModal"

import NewObjectVideo from "../../content/video/NewObject.mp4";
import SelectionToolVideo from "../../content/video/SelectionTool.mp4";
import MoveToolVideo  from "../../content/video/MoveTool.mp4";
import OrbitVideo  from "../../content/video/Orbit.mp4";
import RotateToolVideo  from "../../content/video/RotateTool.mp4";
import ScaleToolVideo  from "../../content/video/ScaleTool.mp4";
import PatchTool  from "../../content/video/Patch_tool_square.mp4";
import MirrorTool  from "../../content/video/Mirror_tool_square.mp4";
import AlignTool  from "../../content/video/AlignTool.mp4";
import measure_tool_square  from "../../content/video/measure_tool_square.mp4";

import { 
        TOOL_MODE_Insert,
        TOOL_MODE_Select, 
        TOOL_MODE_Move, 
        TOOL_MODE_Scale, 
        TOOL_MODE_Rotate, 
        TOOL_MODE_Align,
        TOOL_MODE_Insert_Single,
        TOOL_MODE_Insert_Line,
        TOOL_MODE_Insert_Array,
        RESOURCE_TYPE_AssemblyGroup,
        RESOURCE_TYPE_FixtureType,
        RESOURCE_TYPE_Mesh,
        RESOURCE_TYPE_SymbolDef,
        RESOURCE_TYPE_Connection,
        RESOURCE_TYPE_Dimension,
        CONNECTION_TYPE_Rope,
        CONNECTION_TYPE_TrussCross,
        CONNECTION_TYPE_Drop,
        CONNECTION_TYPE_Pipe,
        RESOURCE_TYPE_TextObject,
        RESOURCE_TYPE_CablePath,
        SELECT_Single,
        SELECT_SameName,
        SELECT_SameType,
        SELECT_SameClass,
        SELECT_SameLayer,
        SELECT_SameProperty,
        SELECT_Box, 
        TOOL_MODE_Orbit,
        BASE_UNIT_LENGTH,
        ORBIT_Pan,
        ALIGN_Light,
        ALIGN_AssemblyOrigin,
        TRANSFORM_Global,
        TRANSFORM_Local,
        SELECT_Group_Inside,
        SELECT_Group_Outside,
        CONNECTION_TYPE_Bridle2,
        CONNECTION_TYPE_Bridle3,
        CONNECTION_TYPE_Bridle4,
        TOOL_MODE_Insert_SingleAlign,
        TOOL_MODE_Measure,
        RESOURCE_TYPE_Polygon,
        TOOL_MODE_Camera,
        IsElectronContext,
        RESOURCE_TYPE_PrintLabel,
        TOOL_MODE_Mirror,
        TOOL_MODE_Patch,
        RESOURCE_TYPE,
        TOOL_MODE_INSERT,
        CheckWorkspace,
        TOOL_MODE,
        }  from "../../util/defines";
import QrCodeReader from '../QrCodeScanner/QrCodeScanner';
import { HandleScanInApp } from '../QrCodeScanner/defaultScanning';
import ToolBarToolTip from './ToolBarButton';
import { globalCallbacks } from '../../util/callback';
    

class ToolSelect extends Component 
{
    constructor(props)
    {
        super(props);
        this.state = 
        { 
            activeTool              : TOOL_MODE_Select,
            selectToolMode          : SELECT_Single,
            selectGroupMode         : SELECT_Group_Outside,
            orbitToolMode           : ORBIT_Pan,
            resourceSelectorOpen    : false,
            resourceType            : RESOURCE_TYPE_SymbolDef,
            alignToolMode           : ALIGN_Light,
            InsertToolInsertionMode : TOOL_MODE_Insert_Single,
            transformMode           : TRANSFORM_Global,
            LineToolOffset          : 1000,
            ExtrusionHeight         : 0,

            editMode                : undefined,
            trussCrossOptions       : [],
            properties              : [],
            SymbolDefs              : [],
            FixtureTypes            : [],
            popupOpen               : false
        }
        this.setUpCallbacks();

        this.menuStyle = {
            marginTop: "0px",
            height: "calc(100% - 0.7em)",
            overflowX: "auto",
        }
    }

    componentDidMount() 
    {
        this.fetchEditMode();
        globalCallbacks.refetchSymbols_TOOL()
        globalCallbacks.refetchTrussCrosses()
        globalCallbacks.refetchProperties()
        
    }

    handleToolClick = (e, { name }, dontOpenResourceSelector = false) => 
    {
        switch(name)
        {
            case TOOL_MODE_Insert: { if(globalCallbacks.InsertTool) { globalCallbacks.InsertTool(this.state.resourceType, this.state.InsertToolInsertionMode); } break; }
            case TOOL_MODE_Select: { if(globalCallbacks.SelectTool) { globalCallbacks.SelectTool(); } break; }
            case TOOL_MODE_Move: 
            case TOOL_MODE_Scale: 
            case TOOL_MODE_Rotate: { if(globalCallbacks.UpdateTransformTool) { globalCallbacks.UpdateTransformTool(name); } break; }
            case TOOL_MODE_Align:  { if(globalCallbacks.AlignTool)  { globalCallbacks.AlignTool();  } break; }
            case TOOL_MODE_Orbit:  { if (globalCallbacks.OrbitTool) { globalCallbacks.OrbitTool(); } break; }
            case TOOL_MODE_Measure:{ if (globalCallbacks.MeasureTool) { globalCallbacks.MeasureTool(); } break; }
            case TOOL_MODE_Mirror: { if (globalCallbacks.MirrorTool) { globalCallbacks.MirrorTool(); } break; }
            case TOOL_MODE_Camera: { if (globalCallbacks.ShowCameraInApp) { globalCallbacks.ShowCameraInApp(); } break; }
            case TOOL_MODE_Patch: { if (globalCallbacks.PatchTool) { globalCallbacks.PatchTool(); } break; }
            default: break;
        }

        let resourceSelectOpen = false;
        if (name === TOOL_MODE_Insert && !this.state.editMode)
        {
            let lastEntries = undefined
            if(this.state.resourceType === RESOURCE_TYPE_SymbolDef)
            {
                lastEntries = this.state.SymbolDefs
            }
            else if(this.state.resourceType === RESOURCE_TYPE_FixtureType)
            {
                lastEntries = this.state.FixtureTypes
            }

            if (Array.isArray(lastEntries) && lastEntries.length === 0)
            {
                resourceSelectOpen = true
            }

        }

        this.setState({ activeTool: name, resourceSelectorOpen: resourceSelectOpen && !dontOpenResourceSelector });
    }

    handleToolChangeToVr(tool, mode){
        if(globalCallbacks.HandleToolsChangeVR){
            globalCallbacks.HandleToolsChangeVR(tool, mode)
        }
    }

    handleMenuBarClick_Select = (e, { name }) => 
    {

        const mode = parseInt(name);
        globalCallbacks.selectionMode = mode;
        this.setState({ selectToolMode: mode });
        if(globalCallbacks.SelectTool) { globalCallbacks.SelectTool(); }
        this.handleToolChangeToVr(globalCallbacks.toolMode, name)
    }

    changeCurrentResourceType(newType){
        globalCallbacks.InsertTool(newType, this.state.InsertToolInsertionMode, this.state.LineToolOffset)
        this.setState({resourceType: newType})
    }

    handleMenuBarClick_InsertObject = async (resourceType, openRessourceSelector = undefined) => 
    {
        this.changeCurrentResourceType(resourceType)

        if (globalCallbacks.refreshServerResources) { globalCallbacks.refreshServerResources(); }
        if (globalCallbacks.activeResourceChanged)  { globalCallbacks.activeResourceChanged(); }
        
        if([RESOURCE_TYPE.FixtureType, RESOURCE_TYPE.Mesh, RESOURCE_TYPE.SymbolDef].includes(resourceType)) {openRessourceSelector = true;}

        //Polygon does not have Point insertion
        this.setState({ resourceSelectorOpen : openRessourceSelector }, ()=>{
            if(resourceType === RESOURCE_TYPE.Polygon && !(this.state.InsertToolInsertionMode === TOOL_MODE_INSERT.Line || this.state.InsertToolInsertionMode === TOOL_MODE_INSERT.Array)){
                this.handleMenuBarClick_InsertObjectType(TOOL_MODE_INSERT.Line)
            }
        });
    }

    handleMenuBarClick_ConnectionType= async (type) => 
    {
        globalCallbacks.InsertTool(this.state.resourceType, type, this.state.LineToolOffset)

        this.setState({renderMenuCommandConnectionType: type})
    }

    handleMenuBarClick_InsertObjectType = async (type) => 
    {
        globalCallbacks.InsertTool(this.state.resourceType, type, this.state.LineToolOffset)

        if (globalCallbacks.refreshServerResources) { globalCallbacks.refreshServerResources(); }

        let openResourceSelector = false
        if (globalCallbacks.ResourceType === RESOURCE_TYPE_Connection && type === CONNECTION_TYPE_Drop)
        {
            openResourceSelector = true 
        }
        this.setState({InsertToolInsertionMode: type, resourceSelectorOpen: openResourceSelector})
    }

    handleMenuBarClick_InsertObject_close = () => 
    {
        this.setState({ resourceSelectorOpen : false });
    }

    handleMenuBarClick_Orbit = (orbitMode) => 
    {
        globalCallbacks.orbitMode = orbitMode
        this.setState({ orbitToolMode: orbitMode });
        if (globalCallbacks.OrbitTool) { globalCallbacks.OrbitTool(); }
    }

    handleMenuBarClick_Align = (alignMode) => 
    {
        globalCallbacks.alignMode = alignMode
        this.setState({ alignToolMode: alignMode });
        if (globalCallbacks.AlignTool) { globalCallbacks.AlignTool(); }
        this.handleToolChangeToVr(globalCallbacks.toolMode, alignMode)
    }

    handleMenuBarClick_Transform = (transformMode) => 
    {
        globalCallbacks.transformMode = Number(transformMode)
        this.setState({transformMode: Number(transformMode)})
        if (globalCallbacks.UpdateTransformSpace) { globalCallbacks.UpdateTransformSpace(); }
        this.handleToolChangeToVr(globalCallbacks.toolMode, transformMode)
    }


    ActivateScaleByRefTool = () => 
    {
        globalCallbacks.ActivateScaleByRefTool()
    }



    render() 
    {
        const { activeTool } = this.state;
        let workspace = CheckWorkspace()
        
        return (
        <div className="Tool">
        <SelectResourceModal 
                        open={this.state.resourceSelectorOpen} 
                        close={this.handleMenuBarClick_InsertObject_close} 
                        resourceType={this.state.resourceType === RESOURCE_TYPE_Connection ? RESOURCE_TYPE_SymbolDef : this.state.resourceType} 
                        handleChangeType={(resType)=>{this.changeCurrentResourceType(resType)}}
                        
                        />
        <Menu icon='labeled' vertical={this.props.vertical} size="mini" style={this.menuStyle}>
        {workspace.Tools.NewObject ? 
                                    <ToolBarToolTip VIDEO={NewObjectVideo} value="TOOL_MODE_Insert" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
                                        <Menu.Item  name={TOOL_MODE_Insert} active={activeTool === TOOL_MODE_Insert} onClick={() => {this.handleToolClick(undefined, {name: TOOL_MODE_Insert}); if (activeTool === TOOL_MODE_Insert){this.handleMenuBarClick_InsertObject(this.state.resourceType, !!this.state.editMode/*open*/)}}} className="Tool_Insert"> 
                                            <Icon name='add'/>
                                            {LocalizedStrings.Insert}
                                        </Menu.Item>
                                    </ToolBarToolTip> : null}
        {workspace.Tools.Select ?  
                                    <ToolBarToolTip  VIDEO={SelectionToolVideo} value="TOOL_MODE_Select" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
                                        <Menu.Item  name={TOOL_MODE_Select} active={activeTool === TOOL_MODE_Select} onClick={this.handleToolClick} className="Tool_Select"> 
                                            <Icon name='mouse pointer' />
                                            {LocalizedStrings.Select}
                                        </Menu.Item>
                                    </ToolBarToolTip> : null}
        {workspace.Tools.Move ?  
                                    <ToolBarToolTip VIDEO={MoveToolVideo} value="TOOL_MODE_Move" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
                                        <Menu.Item name={TOOL_MODE_Move}  active={activeTool === TOOL_MODE_Move} onClick={this.handleToolClick} className="Tool_Move">
                                            <Icon name='move' />
                                            {LocalizedStrings.Move}
                                        </Menu.Item>
                                    </ToolBarToolTip> : null}
        {workspace.Tools.Scale ?  
                                    <ToolBarToolTip VIDEO={ScaleToolVideo} value="TOOL_MODE_Scale" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
                                        <Menu.Item name={TOOL_MODE_Scale}  active={activeTool === TOOL_MODE_Scale} onClick={this.handleToolClick} >
                                            <Icon name='expand arrows alternate' />
                                            {LocalizedStrings.Scale}
                                        </Menu.Item>
                                    </ToolBarToolTip> : null}
        {workspace.Tools.Rotate ?  
                                    <ToolBarToolTip VIDEO={RotateToolVideo} value="TOOL_MODE_Rotate" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
                                        <Menu.Item name={TOOL_MODE_Rotate}  active={activeTool === TOOL_MODE_Rotate} onClick={this.handleToolClick}>
                                            <Icon name='redo alternate' />
                                            {LocalizedStrings.Rotate}
                                        </Menu.Item>
                                    </ToolBarToolTip> : null}
        {workspace.Tools.Mirror ?  
                                    <ToolBarToolTip VIDEO={MirrorTool} value="TOOL_MODE_Mirror" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
                                        <Menu.Item name={TOOL_MODE_Mirror} active={activeTool === TOOL_MODE_Mirror} onClick={this.handleToolClick}>
                                            <Icon name="random"/>
                                            {LocalizedStrings.Mirror}
                                        </Menu.Item>
                                    </ToolBarToolTip>: null}
        {workspace.Tools.Align ?  
                                    <ToolBarToolTip VIDEO={AlignTool} value="TOOL_MODE_Align" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
                                        <Menu.Item name={TOOL_MODE_Align}  active={activeTool === TOOL_MODE_Align} onClick={this.handleToolClick}>
                                            <Icon name='bullseye' />
                                            {LocalizedStrings.Align}
                                        </Menu.Item>
                                    </ToolBarToolTip> : null}
        {workspace.Tools.Orbit ?   
                                    <ToolBarToolTip VIDEO={OrbitVideo} value="TOOL_MODE_Orbit" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
                                        <Menu.Item name={TOOL_MODE_Orbit} active={activeTool === TOOL_MODE_Orbit} onClick={this.handleToolClick}>
                                            <Icon name="hand paper"/>
                                            {LocalizedStrings.Orbit}
                                        </Menu.Item>
                                    </ToolBarToolTip> : null}
        {workspace.Tools.Measure ?   
                                    <ToolBarToolTip  VIDEO={measure_tool_square} value="TOOL_MODE_Measure" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
                                        <Menu.Item name={TOOL_MODE_Measure} active={activeTool === TOOL_MODE_Measure} onClick={this.handleToolClick}>
                                            <Icon name="arrows alternate horizontal"/>
                                            {LocalizedStrings.Measure}
                                        </Menu.Item>
                                    </ToolBarToolTip>: null}
        {workspace.Tools.Patch ? 
                                    <ToolBarToolTip  VIDEO={PatchTool} value="TOOL_MODE_Patch" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
                                        <Menu.Item name={TOOL_MODE_Patch} active={activeTool === TOOL_MODE_Patch} onClick={this.handleToolClick}>
                                            <Icon name="power cord"/>
                                            {LocalizedStrings.Patch}
                                        </Menu.Item>
                                    </ToolBarToolTip> : null}
        
        {IsElectronContext() ? <ToolBarToolTip value="TOOL_MODE_Camera" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}><Menu.Item name={TOOL_MODE_Camera} active={activeTool === TOOL_MODE_Camera} onClick={this.handleToolClick}>
            <Icon name="camera"/>
            {LocalizedStrings.Camera}
        </Menu.Item></ToolBarToolTip> : null}
        <Menu.Item>
            {""}
        </Menu.Item>
        {this.GetToolModeBar()}
        </Menu>
            
        <QrCodeReader 
        open={this.state.activeTool === TOOL_MODE_Camera}
        noCancelButton
        onScan={HandleScanInApp}
        onCancelClick={()=>{ this.handleToolClick(undefined, {name: TOOL_MODE_Select})}}
        onOkClick={()=>{this.handleToolClick(undefined, {name: TOOL_MODE_Select})}}
        title={"Import QR Code"}
        size="small"
        noCancel={true}
        />
        
        </div>
        )
    }

    GetToolModeBar()
    {
        const { activeTool } = this.state;
        if     (activeTool === TOOL_MODE_Select) { return  this.GetToolModeBar_Selection() }
        else if(activeTool === TOOL_MODE_Insert) { return  this.GetToolModeBar_InsertObject() }
        else if (activeTool === TOOL_MODE_Orbit) { return this.GetToolModeBar_Orbit(); }
        else if (activeTool === TOOL_MODE_Align) { return this.GetToolModeBar_Align(); }
        else if (activeTool === TOOL_MODE_Move || activeTool === TOOL_MODE_Rotate || activeTool === TOOL_MODE_Scale) { return this.GetToolModeBar_Transform() }

        return null
    }
    

    GetToolModeBar_Selection()
    {
        const { selectToolMode } = this.state;

        return(
            <React.Fragment>
            <ToolBarToolTip value="SELECT_Single" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
            <Menu.Item   name={SELECT_Single.toString()} active={selectToolMode === SELECT_Single} onClick={this.handleMenuBarClick_Select}  className="Tool_Select_mode" style={{backgroundColor: selectToolMode === SELECT_Single ? "rgb(212, 212, 212)" : "rgb(230, 230, 230)"}}> 
                <Icon.Group size='big'>
                    <Icon name='hand point up' />
                </Icon.Group>
                {LocalizedStrings.SELECT_Single}
            </Menu.Item >
            </ToolBarToolTip>
            <ToolBarToolTip value="SELECT_SameName" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
            <Menu.Item   name={SELECT_SameName.toString()} active={selectToolMode === SELECT_SameName} onClick={this.handleMenuBarClick_Select} className="Tool_Select_mode" style={{backgroundColor: selectToolMode === SELECT_SameName ? "rgb(212, 212, 212)" : "rgb(230, 230, 230)"}}> 
                <Icon.Group size='big'>
                    <Icon name='tag'/>
                </Icon.Group>
                {LocalizedStrings.SELECT_SameName}
            </Menu.Item >
            </ToolBarToolTip>
            <ToolBarToolTip value="SELECT_SameType" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
            <Menu.Item   name={SELECT_SameType.toString()} active={selectToolMode === SELECT_SameType} onClick={this.handleMenuBarClick_Select} className="Tool_Select_mode" style={{backgroundColor: selectToolMode === SELECT_SameType ? "rgb(212, 212, 212)" : "rgb(230, 230, 230)"}}> 
                <Icon.Group size='big'>
                    <Icon name='hashtag' />
                </Icon.Group>
                {LocalizedStrings.SELECT_SameType}
            </Menu.Item >
            </ToolBarToolTip>
            <ToolBarToolTip value="SELECT_SameClass" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
            <Menu.Item   name={SELECT_SameClass.toString()} active={selectToolMode === SELECT_SameClass} onClick={this.handleMenuBarClick_Select} className="Tool_Select_mode" style={{backgroundColor: selectToolMode === SELECT_SameClass ? "rgb(212, 212, 212)" : "rgb(230, 230, 230)"}}> 
                <Icon.Group size='big'>
                    <Icon name='tags' />
                </Icon.Group>
                {LocalizedStrings.SELECT_SameClass}
            </Menu.Item >
            </ToolBarToolTip>
            <ToolBarToolTip value="SELECT_SameLayer" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
            <Menu.Item   name={SELECT_SameLayer.toString()} active={selectToolMode === SELECT_SameLayer} onClick={this.handleMenuBarClick_Select} className="Tool_Select_mode" style={{backgroundColor: selectToolMode === SELECT_SameLayer ? "rgb(212, 212, 212)" : "rgb(230, 230, 230)"}}> 
                <Icon.Group size='big'>
                    <Icon name='sitemap' />
                </Icon.Group>
                {LocalizedStrings.SELECT_SameLayer}
            </Menu.Item >
            </ToolBarToolTip>

            <ToolBarToolTip value="SELECT_SameProperty" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
            <Menu.Item   name={SELECT_SameProperty.toString()} active={selectToolMode === SELECT_SameProperty} onClick={this.handleMenuBarClick_Select} className="Tool_Select_mode" style={{backgroundColor: selectToolMode === SELECT_SameProperty ? "rgb(212, 212, 212)" : "rgb(230, 230, 230)"}}> 
                <Icon.Group size='big'>
                    <Icon name='elementor' />
                </Icon.Group>
                {LocalizedStrings.SELECT_SameProperty}
            </Menu.Item >
            </ToolBarToolTip>

            <ToolBarToolTip value="SELECT_Box" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
            <Menu.Item   name={SELECT_Box.toString()} active={selectToolMode === SELECT_Box} onClick={this.handleMenuBarClick_Select} className="Tool_Select_mode" style={{backgroundColor: selectToolMode === SELECT_Box ? "rgb(212, 212, 212)" : "rgb(230, 230, 230)"}}> 
                <Icon.Group size='big'>
                    <Icon name='square outline' />
                </Icon.Group>
                {LocalizedStrings.SELECT_Box}
            </Menu.Item >
            </ToolBarToolTip>
            {this.renderSelectionOptions()}
            </React.Fragment>
        )
    }

    renderSelectionOptions = () =>
    {
        if (this.state.activeTool !== TOOL_MODE_Select) { return null; }

        if(this.state.activeTool === TOOL_MODE_Select && this.state.selectToolMode === SELECT_SameProperty ) {
            return this.GetToolModeBar_InsertProperty()
        }
        
        if (this.state.selectToolMode !== SELECT_SameName && this.state.selectToolMode !== SELECT_SameType && this.state.selectToolMode !== SELECT_SameClass && this.state.selectToolMode !== SELECT_SameLayer)
        {
            return null;
        }
        let {selectGroupMode} = this.state

        return (
            <>
                <ToolBarToolTip value="select_outsidegroup" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
                    <Menu.Item  key="select_outsidegroup" onClick={() => {this.handleMenuBarClick_Select_Group(SELECT_Group_Outside)}} active={selectGroupMode === SELECT_Group_Outside} className="Tool_Select_mode" style={{backgroundColor: selectGroupMode === SELECT_Group_Outside ? "rgb(212, 212, 212)" : "rgb(230, 230, 230)"}}>
                        <Icon name="object ungroup"/>
                        {LocalizedStrings.SELECT_OutsideGroup}
                    </Menu.Item >
                </ToolBarToolTip>
                <ToolBarToolTip value="select_insidegroup" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
                    <Menu.Item  key="select_insidegroup" onClick={() => {this.handleMenuBarClick_Select_Group(SELECT_Group_Inside)}} active={selectGroupMode === SELECT_Group_Inside} className="Tool_Select_mode" style={{backgroundColor: selectGroupMode === SELECT_Group_Inside ? "rgb(212, 212, 212)" : "rgb(230, 230, 230)"}}>
                        <Icon name="object group"/>
                        {LocalizedStrings.SELECT_InsideGroup}
                    </Menu.Item >
                </ToolBarToolTip>
            </>
        )
    }

    handleMenuBarClick_Select_Group = (groupMode) =>
    {
        globalCallbacks.selectionGroupMode = groupMode
        this.setState({selectGroupMode: groupMode});
    }



    renderMenuCommandInsert = (iconName, name, ressourceType, rotated = undefined) =>
    {
        return(
            <ToolBarToolTip value={name} popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
            <Menu.Item  key={name} onClick={() => this.handleMenuBarClick_InsertObject(ressourceType)} active={globalCallbacks.ResourceType === ressourceType} className="Tool_Select_mode" style={{backgroundColor: globalCallbacks.ResourceType === ressourceType ? "rgb(212, 212, 212)" : "rgb(230, 230, 230)"}}>
                <Icon name={iconName} rotated={rotated}/> 
                {LocalizedStrings[name] }
            </Menu.Item >
            </ToolBarToolTip>
        )
    }

    renderMenuCommandInsert_Grid = (iconName, name, ressourceType, rotated = undefined) =>
    {
        let style= {
            height:"100%",
            paddingBottom:"0em",
            paddingLeft:"0em",
            paddingRight:"0em",
            paddingTop:"0em"
        }

        let cellStyle = {
            margin:"0",
            padding:"0",
        }

        let active = globalCallbacks.ResourceType === ressourceType
        return (
          <ToolBarToolTip value={name} popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
          <Menu.Item 
            style={{...style, backgroundColor: globalCallbacks.ResourceType === ressourceType ? "rgb(212, 212, 212)" : "rgb(230, 230, 230)"}}
            className="Tool_Select_mode"
            key={name}
            onClick={() => this.handleMenuBarClick_InsertObject(ressourceType)}
            active={globalCallbacks.ResourceType === ressourceType}
          >
            {active ? (
              <b title={LocalizedStrings[name] }>
                <Icon name={iconName} rotated={rotated} />  
                <p style={cellStyle}>{LocalizedStrings[name]}</p>
              </b>
              
            ) : (
              <span title={LocalizedStrings[name] }>
                <Icon name={iconName} rotated={rotated} /> 
                 <p style={cellStyle}>{LocalizedStrings[name]}</p> 
              </span>
            )}
          </Menu.Item >
          </ToolBarToolTip>
        );
    }

    renderMenuCommandInsertType = (iconName, name, type, count) =>
    {
        let icons = []
        for(let i = 0; i < count; i++) { icons.push(i) }
        return(
            <ToolBarToolTip value={name} popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
            <Menu.Item className="Tool_Select_mode"  onClick={() => this.handleMenuBarClick_InsertObjectType(type)} active={this.state.InsertToolInsertionMode === type} style={{backgroundColor: this.state.InsertToolInsertionMode === type ? "rgb(212, 212, 212)" : "rgb(230, 230, 230)"}}>
                <Icon.Group>
                    {icons.map((it, i)=>{ return <Icon key={i} name={iconName}/>})}
                </Icon.Group>
                {LocalizedStrings[name]}
            </Menu.Item >
            </ToolBarToolTip>
        )
    }

    renderMenuCommandConnectionType = (iconName, name, type) =>
    {
        return(
            <ToolBarToolTip value={type} popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
            <Menu.Item  onClick={() => this.handleMenuBarClick_ConnectionType(type)} active={this.state.renderMenuCommandConnectionType === type} className="Tool_Select_mode" style={{backgroundColor: this.state.renderMenuCommandConnectionType === type ? "rgb(212, 212, 212)" : "rgb(230, 230, 230)"}}>
                <Icon name={iconName}/>
                {name}
            </Menu.Item >
            </ToolBarToolTip>
        )
    }

    GetToolModeBar_InsertObject()
    {
        let lastEntries = []
        let call =""
        if(this.state.resourceType === RESOURCE_TYPE_SymbolDef)
        {
            lastEntries = this.state.SymbolDefs
            call = "LR_SetDefaultSymbolDef"
        }
        else if(this.state.resourceType === RESOURCE_TYPE_FixtureType)
        {
            lastEntries = this.state.FixtureTypes
            call = "LR_SetDefaultFixtureType"
        }

        return(
            <React.Fragment>
                {this.state.editMode ? this.GetToolModeBar_InsertInEdit() : this.GetToolModeBar_InsertInDrawing()}
                {this.GetToolModeBar_InsertObject_Option()}
                {this.GetToolModeBar_LastUsed(lastEntries, call)}
            </React.Fragment>
        )
    }

    GetToolModeBar_LastUsed(entries, call)
    {

            return<>
            {
            entries.map(sym => 
            {
                let previewTexture = undefined
                if (sym.PreviewTexture)
                {
                    let textureObject = this.state.previewTextures[sym.PreviewTexture]
                    if (textureObject)
                    {
                      previewTexture = textureObject.Buffer
                    }
                }

            let name = sym.Name
            if(name.length > 12)
            {
                name = name.substring(0, 12);
            }

            return(
                <Menu.Item  key={sym.UUID} onClick={()=>window[call]({UUID: sym.UUID})} title={sym.Name}>
                {previewTexture ? <img style={{height:"3em", width: "3em",}} src={"data:image/png;base64," + previewTexture} alt="preview"/> : null}              
                {name}
                </Menu.Item >)
            })
            }
            </>        
    }

    GetToolModeBar_InsertObject_Option()
    {
        let isPrintLabelMode = this.state.editMode?.UUID && this.state.editMode?.ResourceType === RESOURCE_TYPE.PrintLabel
        if(globalCallbacks.ResourceType === RESOURCE_TYPE_Polygon)
        {
            return (
                <>
                    <Menu.Item>
                        {""}
                    </Menu.Item>
                    {this.renderMenuCommandInsertType("map pin", "LineInsertion", TOOL_MODE_INSERT.Line, 2)}
                    {this.renderMenuCommandInsertType("map pin", "ArrayInsertion", TOOL_MODE_INSERT.Array, 3)}
                    {this.renderMenuCommandInsertType("map pin", "RectangleInsertion", TOOL_MODE_INSERT.Rectangle, 3)}
                    {
                        isPrintLabelMode ? null : 
                        <div style={{width:"40em", paddingTop:"0.5em"}}>
                            <UnitInput name="ExtrusionHeight" label={LocalizedStrings.ExtrusionHeight} baseUnit={BASE_UNIT_LENGTH} value={this.state.ExtrusionHeight} onStateUpdate={(name, val) => { globalCallbacks.ExtrusionHeight =  val; this.setState({ExtrusionHeight: val})}}/>
                        </div>
                    }
    
                </>)
        }
        if(globalCallbacks.ResourceType === RESOURCE_TYPE_CablePath)
        {
            return (
                <>
                    <Menu.Item>
                        {""}
                    </Menu.Item>
                    {this.renderMenuCommandInsertType("map pin", "LineInsertion", TOOL_MODE_Insert_Line, 2)}
                    {this.renderMenuCommandInsertType("map pin", "ArrayInsertion", TOOL_MODE_Insert_Array, 3)}    
                </>)
        }
        if(globalCallbacks.ResourceType === RESOURCE_TYPE_Connection)
        {
            return (
            <>
                <Menu.Item>
                    {""}
                </Menu.Item>
                {this.renderMenuCommandInsertType("window minimize outline", "RopeConnection", CONNECTION_TYPE_Rope)}
                {this.renderMenuCommandInsertType("plus square outline", "TrussCross", CONNECTION_TYPE_TrussCross)}
                {this.renderMenuCommandInsertType("plus square outline", "Drop", CONNECTION_TYPE_Drop)}
                {this.renderMenuCommandInsertType("plus square outline", "Pipe", CONNECTION_TYPE_Pipe)}
                {this.renderMenuCommandInsertType("plus square outline", "Bridle2", CONNECTION_TYPE_Bridle2)}
                {this.renderMenuCommandInsertType("plus square outline", "Bridle3", CONNECTION_TYPE_Bridle3)}
                {this.renderMenuCommandInsertType("plus square outline", "Bridle4", CONNECTION_TYPE_Bridle4)}
                {this.state.InsertToolInsertionMode === CONNECTION_TYPE_Pipe ? <><Menu.Item>{""}</Menu.Item>{this.GetToolModeBar_InsertPipe()}</> : null}


            </>)
        }
        if(globalCallbacks.ResourceType === RESOURCE_TYPE_Dimension)
        {
            return null
        }
        return(
            <>
                <Menu.Item>
                    {""}
                </Menu.Item>
                {this.renderMenuCommandInsertType("map pin", "SinglePointInsertion", TOOL_MODE_Insert_Single, 1)}
                {this.renderMenuCommandInsertType("map pin", "SinglePointAlignInsertion", TOOL_MODE_Insert_SingleAlign, 2)}
                {this.renderMenuCommandInsertType("map pin", "LineInsertion", TOOL_MODE_Insert_Line, 2)}
                {this.renderMenuCommandInsertType("map pin", "ArrayInsertion", TOOL_MODE_Insert_Array, 3)}
                {this.state.InsertToolInsertionMode ? <><Menu.Item>{""}</Menu.Item>{this.GetToolModeBar_InsertLine()}</> : null}
            </>
        )

        
    }

    GetToolModeBar_InsertInDrawing()
    {
        return (
            <React.Fragment>
                {this.renderMenuCommandInsert("boxes",              "SymbolDefinition",      RESOURCE_TYPE_SymbolDef)}
                {this.renderMenuCommandInsert("lightbulb",          "GDTF",             RESOURCE_TYPE_FixtureType)}
                {this.renderMenuCommandInsert("cubes",              "Mesh",             RESOURCE_TYPE_Mesh)}
                <div style={{minWidth: "3em"}}/>
                {this.renderMenuCommandInsert("code",               "Connection",       RESOURCE_TYPE_Connection)}
                {this.renderMenuCommandInsert("font",               "Text",       RESOURCE_TYPE_TextObject)}
                {this.renderMenuCommandInsert("plug",               "CablePath",       RESOURCE_TYPE_CablePath)}
                {this.renderMenuCommandInsert("tag",                "Dimension",        RESOURCE_TYPE_Dimension)}
                {this.renderMenuCommandInsert("wrench",             "AssemblyGroup",    RESOURCE_TYPE_AssemblyGroup, "clockwise")}
                {this.renderMenuCommandInsert("pencil alternate",   "Polygon",          RESOURCE_TYPE_Polygon, "clockwise")}
            </React.Fragment>)
    }

    GetToolModeBar_InsertInEdit()
    {
        let stylegrid = {
            marginTop:"0",
            marginBottom:"0em",
            marginLeft:"0em",
            marginRight:"0em",
            minWidth:"35.5em",
            maxWidth:"37em",
            height:"100%",
            fontSize: "1.085em"
        
            
        }
        let styleRow = {
            paddingTop:"0em",
            paddingBottom:"0em",
            marginTop:"0em",
            marginBottom:"0em"
        }
        let styleColumn = {
            paddingLeft:"0em",
            paddingRight:"0em"
        }

        const tools = this.getEditTools()

        // order the tools in a grid with width 'columnCount'
        const columnCount = 7
        let rows = []
        for (let i=0; i < tools.length; i += columnCount)
        {
            const columns = tools.slice(i, i+columnCount)

            while (columns.length < columnCount) {
                columns.push({ isPlaceholder: true }); 
            }

            rows.push(
                <Grid.Row style={styleRow} key={`row-${i}`}>
                    {columns.map((tool, j) =>
                        tool.isPlaceholder ? (
                            <Grid.Column key={`placeholder-${j}`} style={styleColumn}></Grid.Column>
                        ) : (
                            <Grid.Column key={j} style={styleColumn}>
                                {this.renderMenuCommandInsert_Grid(tool.iconName, tool.name, tool.resourceType, tool.rotated)}
                            </Grid.Column>
                        )
                    )}
                </Grid.Row>
            );
        }

        return (
          <React.Fragment>
            <Grid style={stylegrid} columns={7} container>
              {rows}
            </Grid>
          </React.Fragment>
        );
    }

    getEditTools()
    {
        switch (this.state.editMode.ResourceType) {
          case RESOURCE_TYPE_PrintLabel:
            return [
                { iconName: "ban",                name: "AssemblyGroup",   resourceType: RESOURCE_TYPE.AssemblyGroup,   rotated: "clockwise" },
                { iconName: "pencil alternate",   name: "Polygon",         resourceType: RESOURCE_TYPE.Polygon,         rotated: undefined },
                { iconName: "print",              name: "PrintLabelField", resourceType: RESOURCE_TYPE.PrintLabelField, rotated: undefined }
              ];
          default:
            return [
              { iconName: "ban",                name: "Group",   resourceType: RESOURCE_TYPE_AssemblyGroup,   rotated: "clockwise" },
              { iconName: "file",               name: "Mesh",            resourceType: RESOURCE_TYPE.Mesh,            rotated: undefined },
              { iconName: "magnet",             name: "Magnet",          resourceType: RESOURCE_TYPE.Magnet,          rotated: undefined },
              { iconName: "plug",               name: "Electrical",      resourceType: RESOURCE_TYPE.Electrical,      rotated: undefined },
              { iconName: "cubes",              name: "Inventory",       resourceType: RESOURCE_TYPE.Inventory,       rotated: undefined },
              { iconName: "won",                name: "Structure",       resourceType: RESOURCE_TYPE.Structure,       rotated: undefined },
              { iconName: "upload",             name: "Support",         resourceType: RESOURCE_TYPE.Support,         rotated: undefined },
              { iconName: "file audio",         name: "Audio",           resourceType: RESOURCE_TYPE.Audio,           rotated: undefined },
              { iconName: "map pin",            name: "Origin",          resourceType: RESOURCE_TYPE.Origin,          rotated: undefined },
              { iconName: "th",                 name: "Curtain",         resourceType: RESOURCE_TYPE.Curtain,         rotated: undefined },
              { iconName: "pencil alternate",   name: "Polygon",         resourceType: RESOURCE_TYPE.Polygon,         rotated: undefined },
              { iconName: "weight",             name: "Load",            resourceType: RESOURCE_TYPE.Load,            rotated: undefined },
              { iconName: "venus mars",         name: "Dock",            resourceType: RESOURCE_TYPE.Dock,            rotated: undefined },
            ];
        }
    }

    GetToolModeBar_InsertLine()
    {
        return (<React.Fragment>
            <div style={{width:"40em", minWidth:"10em"}}>
                <UnitInput name="LineToolOffset" label={LocalizedStrings.Distance} baseUnit={BASE_UNIT_LENGTH} value={this.state.LineToolOffset} onStateUpdate={(name, val) => { globalCallbacks.InsertTool(this.state.resourceType, this.state.InsertToolInsertionMode, val); this.setState({LineToolOffset: val})}}/>
            </div>
        </React.Fragment>)
    }

    GetToolModeBar_InsertPipe()
    {
        return (<React.Fragment>
            <div style={{width:"40em", paddingTop:"0.5em"}}>
                <Dropdown
                    style={{maxWidth:"30em",position:"absolute", zIndex:"2"}}
                    placeholder={LocalizedStrings.trussCrossSelect}
                    fluid
                    selection
                    options={this.state.trussCrossOptions}
                    onChange={(e, {value}) => {globalCallbacks.CurrentTrussCross = value}}
                />

            </div>
        </React.Fragment>)
    }

    GetToolModeBar_InsertProperty()
    {
        return (<React.Fragment>
            <div style={{width:"30em", paddingTop:"0.5em", marginLeft:"0.5em"}}>
                <Dropdown
                    style={{maxWidth:"30em",position:"absolute", zIndex:"2"}}
                    placeholder={LocalizedStrings.propertySelect}
                    fluid
                    selection
                    search
                    onChange={(e, entry) => {
                        const {value} = entry 

                        if(typeof value === "string"){
                            let splited = value.split("__")
                            
                            if(splited.length > 0){
                                globalCallbacks.CurrentProperty =  splited[1]
                                globalCallbacks.CurrentArrayName =  splited[0]
                            }
                        }
                    }}
                    options={this.state.properties}
                />

            </div>
        </React.Fragment>)
    }

    GetToolModeBar_Orbit()
    {
        return (<React.Fragment>
            <ToolBarToolTip value="ORBIT_Pan" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
            <Menu.Item className="Tool_Select_mode"  onClick={() => this.handleMenuBarClick_Orbit(ORBIT_Pan)} active={globalCallbacks.orbitMode === ORBIT_Pan} style={{backgroundColor: globalCallbacks.orbitMode === ORBIT_Pan ? "rgb(212, 212, 212)" : "rgb(230, 230, 230)"}}>
                <Icon name="move" />                
                {LocalizedStrings.Pan}
            </Menu.Item >
            </ToolBarToolTip>
        </React.Fragment>)
    }

    GetToolModeBar_Align()
    {
        let {alignToolMode} = this.state
        return (<React.Fragment>
            <ToolBarToolTip value="ALIGN_Light" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
            <Menu.Item className="Tool_Select_mode" onClick={() => this.handleMenuBarClick_Align(ALIGN_Light)} active={alignToolMode === ALIGN_Light} style={{backgroundColor: alignToolMode === ALIGN_Light ? "rgb(212, 212, 212)" : "rgb(230, 230, 230)"}}>
                <Icon name="lightbulb" />
                {LocalizedStrings.AlignLight}
            </Menu.Item >
            </ToolBarToolTip>
            <ToolBarToolTip value="ALIGN_AssemblyOrigin" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
            <Menu.Item className="Tool_Select_mode" onClick={() => this.handleMenuBarClick_Align(ALIGN_AssemblyOrigin)} active={alignToolMode === ALIGN_AssemblyOrigin} style={{backgroundColor: alignToolMode === ALIGN_AssemblyOrigin ? "rgb(212, 212, 212)" : "rgb(230, 230, 230)"}}>
                <Icon name="arrows alternate" />
                {LocalizedStrings.AlignAssemblyOrigin}
            </Menu.Item >
            </ToolBarToolTip>
        </React.Fragment>)
    }

    GetToolModeBar_Transform()
    {
        let {transformMode} = this.state
        return (<React.Fragment>
            <ToolBarToolTip value="TRANSFORM_Global" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
            <Menu.Item className="Tool_Select_mode" onClick={() => {this.handleMenuBarClick_Transform(TRANSFORM_Global)}} active={transformMode === TRANSFORM_Global} style={{backgroundColor: transformMode === TRANSFORM_Global ? "rgb(212, 212, 212)" : "rgb(230, 230, 230)"}} >
                <Icon name="globe"/>
                {LocalizedStrings.TransformGlobal}
            </Menu.Item >
            </ToolBarToolTip>
            <ToolBarToolTip value="TRANSFORM_Local" popupOpen={this.state.popupOpen} onPopup={(value)=>{this.setState({popupOpen: value})}}>
            <Menu.Item  className="Tool_Move_LocalGlobal Tool_Select_mode" onClick={() =>{this.handleMenuBarClick_Transform(TRANSFORM_Local)}} active={transformMode === TRANSFORM_Local} style={{backgroundColor: transformMode === TRANSFORM_Local ? "rgb(212, 212, 212)" : "rgb(230, 230, 230)"}}>
                <Icon name="expand arrows alternate" />
                {LocalizedStrings.TransformLocal}
            </Menu.Item >

            </ToolBarToolTip>
            {
                this.state.activeTool ===  TOOL_MODE_Scale ? 
                <Menu.Item  className="Tool_Move_Scale Tool_Select_mode" onClick={() =>{this.ActivateScaleByRefTool()}} active={transformMode === TRANSFORM_Local} style={{backgroundColor: transformMode === TRANSFORM_Local ? "rgb(212, 212, 212)" : "rgb(230, 230, 230)"}}>
                    <Icon name="sort" rotated="clockwise"/>
                    {LocalizedStrings.ScaleTool}
                </Menu.Item > : null
            }
        </React.Fragment>)
    }

    fetchEditMode = async () =>
    {
        let editMode = await window.LR_GetGeometryEditMode();

        this.setState({editMode: editMode.EditMode})
    }

    setUpCallbacks()
    {
        globalCallbacks.HandleToolsChange = (tool, mode, dontOpenResourceSelector) =>
        {
            this.handleToolClick(undefined, { name: tool }, dontOpenResourceSelector);
            if(mode){
                if(tool === TOOL_MODE.Select){
                    this.handleMenuBarClick_Select({}, {name: mode})
                    return;
                }else if([TOOL_MODE.Move, TOOL_MODE.Scale, TOOL_MODE.Rotate].includes(tool)){
                    this.handleMenuBarClick_Transform(mode)
                    return;
                }else if(tool === TOOL_MODE.Align){
                    this.handleMenuBarClick_Align(mode)
                    return;
                }else if(tool === TOOL_MODE.Insert){
                    this.handleMenuBarClick_InsertObject(mode, !dontOpenResourceSelector)
                    return;
                }
            }
            this.handleToolChangeToVr(tool)
        }

        globalCallbacks.HandleSelectToolsChange = (mode, group) =>
        {
            this.handleToolClick(undefined, {name: TOOL_MODE_Select})
             this.handleMenuBarClick_Select({}, {name: mode});
             this.handleMenuBarClick_Select_Group(group)
        }


        globalCallbacks.updateToolBar = async () => {
            this.forceUpdate();
        }

        globalCallbacks.activateMove = async () => {
            this.setState({activeTool :TOOL_MODE_Move})
        }

        // This will set the select tool to single select
        globalCallbacks.resetSelectTool = async () => {
            this.setState({selectToolMode: SELECT_Single})
        }

        globalCallbacks.updateToolbarEditMode = () =>
        {
            this.fetchEditMode()
        }

        globalCallbacks.ShowCameraInApp = () =>
        {
            this.setState({qropen: true})
        }

        globalCallbacks.refetchSymbols_TOOL = async() =>
        {
            let symbolDefs        = await window.LR_GetLastUsedSymbolDefs();	
            let fixtureTypes      = await window.LR_GetLastFixtureTypes();	
            
            let texturesMap = {}
            
            
            let getPreviewTexture = async (obj) =>
            {
                let textureUuid = obj.PreviewTexture
                if (textureUuid)
                {
                    let storageKey = "_prev_text" + textureUuid
                    let textureObject = undefined
                    try {
                        textureObject = JSON.parse(sessionStorage.getItem(storageKey))
                    } catch(_) {
                    }
                    if (!textureObject)
                    {
                        textureObject = await window.LR_GetPreviewTextureFromObject({Object: obj.UUID, WriteBuffer: true})
    
                        sessionStorage.setItem(storageKey, JSON.stringify(textureObject))
                    }
                    if (textureObject && textureObject.UUID)
                    {
                        texturesMap[textureObject.UUID] = textureObject
                    }
                }
            }
            
            for (let x of symbolDefs)
            {
                await getPreviewTexture(x)
            }

            for (let x of fixtureTypes)
            {
                await getPreviewTexture(x)
            }


            this.setState({SymbolDefs: symbolDefs, FixtureTypes: fixtureTypes, previewTextures: texturesMap})
        }

        globalCallbacks.refetchTrussCrosses = async () => {
            let trussCrosses = await window.LR_GetTrussCrossSection()
            trussCrosses = trussCrosses.ResourceMap.crossSection
            let trussCrossOptions = trussCrosses.map(tc => ({text: tc.Name, value: tc.Name, key: tc.Name}))
           
            this.setState({trussCrossOptions})
            
        }



        globalCallbacks.refetchProperties = async () => {
            let properties = await window.LR_GetPossibleFields();

            let getLocalizedNameForProp = (prop) =>
            {
                if (!prop.ArrayName) { return LocalizedStrings_ObjectProperties[prop.PropertyName]}
                
                if (LocalizedStrings_ObjectProperties[prop.ArrayName + "_" + prop.PropertyName])
                {
                    return LocalizedStrings_ObjectProperties[prop.ArrayName + "_" + prop.PropertyName]
                }

                return LocalizedStrings_ObjectProperties[prop.PropertyName]
            }

            let options = properties.map(entry=>
                {
                    return {
                        key: entry.ArrayName + "__" + entry.PropertyName,
                        value: entry.ArrayName + "__" + entry.PropertyName,
                        text: getLocalizedNameForProp(entry),
                        PropertyName: entry.PropertyName,
                        ArrayName: entry.ArrayName,
                    }
                })

            this.setState ({properties: options})   

        }
    }


}


export default ToolSelect;