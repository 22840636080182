//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------

import React, {Suspense, } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import {createRoot} from 'react-dom/client';
import {IsDarkTheme, IsElectronContext, IsRunningAsBrowser, ForceOneWindowRouter} from './util/defines'
import WebRouter from './webApp/WebRouter';
import IosRouter from './iosApp/iosRouter';

import ErrorBoundary from './electronApp/ErrorBoundary';
import { Provider } from 'react-redux'
import { FetchGlobalSettings } from './redux/actions/fetch_actions';
import { store } from './redux/store';
import { IsUsingWebassembly } from './webApp/ipcRenderer';

const LightTheme = React.lazy(() => import('./lighttheme'));
const DarkTheme  = React.lazy(() => import('./darktheme'));

let UseDarkTheme = IsDarkTheme() && !IsRunningAsBrowser()

import CONST from "./webApp/CONST"

const ElectronRouter = React.lazy(() => import('./electronApp/ElectronRouter'));
const VectorworksRouter = React.lazy(() => import('./vectorworksApp/VectorworksRouter'));

window.IsSketchup = window.sketchup !== undefined

//---------------------------------------------------------------------------------
// File Drag and Drop Support

let InstallDragAndDropSupport = () => 
{
document.addEventListener("drop", (event) => 
{
    for (const f of event.dataTransfer.files) 
    { 
        let path = f.path.toLocaleLowerCase()
        if(path.endsWith(".lrwx"))
        {
            window.LR_OpenLRWFile({Path:f.path, Async:true});
        }
        else if(path.endsWith(".gdtf"))
        {
            window.LR_ImportGdtf({Path:f.path, Async:true});
        }
        else if(path.endsWith(".mvr"))
        {
            window.LR_ReadMVR({Path:f.path, Async:true});
        }
        else if(path.endsWith(".pdf"))
        {
            window.LR_ReadPDF({Path:f.path, Async:true});
        }
        else if(path.endsWith(".stp"))
        {
            window.LR_ImportDSTV({Path:f.path, Async:true});
        }
        else if(path.endsWith(".dxf"))
        {
            window.LR_ReadDWG({Path:f.path, Async:true});
        }
        else if(path.endsWith(".dwg"))
        {
            window.LR_ReadDWG({Path:f.path, Async:true});
        }
        else if(path.endsWith(".svg"))
        {
            window.LR_ImportSVG({Path:f.path, Async:true});
        }
        else if(path.endsWith(".json"))
        {
            window.LR_Import({Path:f.path, Async:true});
        }
        else if(path.endsWith(".ifc"))
        {
            window.LR_ReadIFC({Path:f.path, Async:true});
        }
        else if(path.endsWith(".skp"))
        {
            window.LR_ReadSketchUp({Path:f.path, Async:true});
        }
        else if(path.endsWith(".fbx") ||
                path.endsWith(".3ds")||
                path.endsWith(".dae")||
                path.endsWith(".obj")||
                path.endsWith(".glb")||
                path.endsWith(".gltf"))
        {
            window.LR_ImportMesh({Path:f.path, Async:true});
        }
        else if(path.endsWith(".png") ||
                path.endsWith(".jpg") ||
                path.endsWith(".jpeg"))
        {
            window.LR_ImportTexture({Path:f.path, Async:true});
        }

        event.preventDefault()
        event.stopPropagation();
        event.stopImmediatePropagation();
    } 
  });

  document.addEventListener('dragover', (e) => 
  {   
    e.preventDefault(); 
    e.stopPropagation(); 
  }); 

}

let LayerLoader = function ({component}) 
{
    FetchGlobalSettings()
    return (
    <Suspense fallback={<Dimmer active inverted><Loader inverted></Loader> </Dimmer>}>
        {component}
    </Suspense>
    );
}

if(CONST.COMPILED_FOR_VW)
{
    window.IsVectorworksContext = true
}

window.LR_SHORTCUTS = {}

let InstallShortCutHandling = async() =>
{
    await import("./component/ShortCutDisplay/ShortCut")

    window.LR_SHORTCUTS = await window.LR_GetShortCutsSettings({})
}

let LoadConnectors = async() =>
{
    let data1 = await window.LR_GetConnectors_Data()
    let data2 = await window.LR_GetConnectors_Electric()

    let globalConnectors_Data = {}
    let globalConnectors_Electrical = {}
    let globalConnectors = {}
    data1.Connectors.forEach(element => 
    {
        globalConnectors_Data[element.ConnectorName] = element
        globalConnectors[element.ConnectorName]      = element
    });
    data2.Connectors.forEach(element => 
    {
        globalConnectors_Electrical[element.ConnectorName] = element
        globalConnectors[element.ConnectorName]             = element
    });

    window.globalConnectors = globalConnectors
    window.globalConnectors_Electrical = globalConnectors_Electrical
    window.globalConnectors_Data = globalConnectors_Data
}

import en from "./localization/raw/en/LightRightObjectsFields.json"
import de from "./localization/raw/de/LightRightObjectsFields.json"

let setLocale = () =>
{

    window.LR_SetTableViewLocalization({Locale: {Worksheet: en}, LANG:"EN"})
    window.LR_SetTableViewLocalization({Locale: {Worksheet: de}, LANG:"DE"})
}

let ThemeChooser = ({children}) => 
{
    return(
        <React.Suspense fallback={<></>}>
        {UseDarkTheme ? <DarkTheme/> :<LightTheme/>}
        {children}
      </React.Suspense>
    )
}

let InstallCheckForUpdate = () => 
{
    setTimeout(() => { window.LR_CheckForUpdate() }, 4000);

}

const root = createRoot(document.getElementById('root'))


let GetRouter = () =>
{
    if(window.IsSketchup || window.IsVectorworksContext || ForceOneWindowRouter() )
    {
        return <VectorworksRouter/>
    }
    return <ElectronRouter/>
}

if(window.ElectronWebsocketMode)
{
    InstallDragAndDropSupport()
    FetchGlobalSettings()
    LoadConnectors()
    setLocale()
    InstallShortCutHandling()
    InstallCheckForUpdate()
    root.render(<ErrorBoundary><ThemeChooser><Provider store={store}><LayerLoader component={GetRouter()}/></Provider></ThemeChooser></ErrorBoundary>);
    
}
else if(IsElectronContext())
{
    InstallDragAndDropSupport()
    FetchGlobalSettings()
    LoadConnectors()
    setLocale()
    InstallShortCutHandling()
    InstallCheckForUpdate()
    root.render(<ErrorBoundary><ThemeChooser><Provider store={store}><LayerLoader component={GetRouter()}/></Provider></ThemeChooser></ErrorBoundary>);
}
else if(window.IsSketchup || window.IsVectorworksContext)
{
    InstallDragAndDropSupport()
    Promise.all([
        import("./util/callback"),
        import("./util/preload")
    ]).then(()=>{
        FetchGlobalSettings()
        LoadConnectors()
        setLocale()
        InstallShortCutHandling()
        InstallCheckForUpdate()
        root.render(<ErrorBoundary><ThemeChooser><Provider store={store}><LayerLoader component={GetRouter()}/></Provider></ThemeChooser></ErrorBoundary>);
    })
}
else if (window.IsIOS || window.Android)
{
    import("./util/preload").then(()=>{
        FetchGlobalSettings()
        LoadConnectors()
        setLocale()
        InstallShortCutHandling()
        InstallCheckForUpdate()
        root.render(<ErrorBoundary><ThemeChooser><Provider store={store}><LayerLoader component={<IosRouter/>}/></Provider></ThemeChooser></ErrorBoundary>);
    })
}
else if(IsRunningAsBrowser())   
{
    Promise.all([
        import("./util/callback"),
        import("./util/preload")
    ]).then(()=>{
    FetchGlobalSettings()
    if(IsUsingWebassembly){
        LoadConnectors()
    }
    root.render(<ErrorBoundary><ThemeChooser><Provider store={store}><WebRouter/></Provider></ThemeChooser></ErrorBoundary>);
    })

}else
{   

    Promise.all([
        import("./util/callback"),
        import("./util/preload")
    ]).then(()=>{
        FetchGlobalSettings()
        root.render(<ErrorBoundary><ThemeChooser><Provider store={store}><WebRouter/></Provider></ThemeChooser></ErrorBoundary>);
    })


}

