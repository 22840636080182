import React, { Component } from "react";
import { Table, Checkbox, Label, Header, Grid, Icon, Segment, Button, Form, Dropdown, Divider } from "semantic-ui-react";
import LocalizedStrings from "../../localization/DiffView";
import LRFilterInput from "../Basics/FilterField";
import { filterApply } from "../MergeView/MergeView";
import DiffRenderer from '../Renderer/DiffRenderer';
import DiffByProperty from "./DiffByProperty";
import { propertyDiffTypes } from './DiffByProperty';

import LocalizedStrings_ObjectProperties from "../../localization/LightRightObjectsFields";
import DiffSummary from "./DiffSummary";

class DiffTable extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      Collapsed: {},
      ShowDiffIn3D: false,
      searchFilter:""
    }


  }
  getDefiningDiffs = () =>
  {
    if(this.props.electronApp)
    {
      return this.state ? this.state.differences : {}
    }
    else
    {
      return this.props ? (this.props.merge ? filterApply(this.props.differences) : this.props.differences) : {}
    }
  }

  filterBySearch(object)
  {
    let filter =(str) =>
    {
      if(String(str).toLowerCase().includes(this.state.searchFilter.toLowerCase()))
      {
        return true
      }
      return false
    }

    if(this.state.searchFilter === "")
    {
      return true
    }

   

    if(filter(object.ObjectName))
    {
      return true
    }

    if( filter(object.ObjectId))
    {
      return true
    }

    
    return false
  }

  render() 
  {
    let entries = []
    let objectDiff = this.getDefiningDiffs()
    entries = Object.keys(this.getDefiningDiffs() ? this.getDefiningDiffs() : {}).map(objectType => { return objectType })

    entries.sort()

    let hasDiffs = objectDiff && objectDiff.ChangeCount > 0

    if(this.props.noRender) 
    {
      return (
      <div key={"DiffTable"}>
        {entries.map(entry =>  this.renderObjectType(objectDiff, entry))}
      </div>)
    }

    return(
    <div key={"DiffTable"} >
      <Grid style={{margin: '5px'}}>
        <Grid.Column width={10} style={{display: "flex", flexDirection: "column", height: "100%"}}>
          {this.props.drawSummary ? <>
            <DiffSummary diff={this.getDefiningDiffs()}/>
            <div style={{paddingTop: "15px"}}/>
          </> : null}
          <Form style={{paddingBottom: "10px"}}>
          <LRFilterInput value = {this.state.searchFilter} onChange={(value)=> {this.setState({searchFilter: value})}}/>
          </Form>
          
          { 
          
            hasDiffs ?
           <div style={{overflowY: "auto", marginTop:"1em", flexGrow: "1", overflowX: "clip"}}> 
              {entries.map(entry =>  this.renderObjectType(objectDiff, entry))}
           </div>
           :   (<Segment placeholder style={{width:"100%", height:"40%"}} > 
                    <Header icon> <Icon name='search' /> {LocalizedStrings.NoCommit} </Header> 
              </Segment> )

           }
          
        </Grid.Column>
        <Grid.Column width={6} style={{height: "100%"}}>
          {this.state.ShowDiffIn3D ? 
            <DiffRenderer rootUuid={0} height={800} differences={this.getDefiningDiffs()} drawing1UUID={this.props.drawing1UUID} drawing2UUID={this.props.drawing2UUID}/> 
            : 
            <>
            <Segment placeholder>
              <Header icon>
              <Icon name='redo' />
              </Header>
              <Button onClick={()=>this.setState({ShowDiffIn3D:true})} primary>{LocalizedStrings.ClickToLoad3D}</Button>
            </Segment>
            </>}
        </Grid.Column>
      </Grid>
    </div>)
  }

  renderObjectType(objectContainer ,objectType, noHeader = false) 
  {
    let hide = this.state["__Hide" + objectType] === true || this.state["__Hide" + objectType] === undefined
    let SortMode = this.state["__Sort" + objectType]

    
    if(typeof objectContainer[objectType] !== "object" ||
      Object.keys(objectContainer[objectType].Changed).length + Object.keys(objectContainer[objectType].Created).length + Object.keys(objectContainer[objectType].Deleted).length === 0)
    {
      return null
    }

    if(hide)
    {
      return (<React.Fragment key={objectType}>
                  
        {
        noHeader ? null : 
        <Header>
          <Icon link name={hide ? "angle right" : "angle down"} onClick={()=>{this.setState({["__Hide" + objectType]: !hide})}}/>
          {objectType}
        </Header>
        }
        </React.Fragment>)
        
    }


    let changed  = []
    let deleted  = []
    let created  = []
    let hasEntry = false

    let SortFunction = (entries) =>
    {
      let Property = SortMode
      if(!Property)
      {
        Property = "ObjectName"
      }
      entries = entries.sort((a,b) => 
      { 
        return a.currentObject[Property] < b.currentObject[Property] 
      })

    }


    if(objectContainer[objectType].Changed) 
    {
      let entries = []
      entries = Object.keys(objectContainer[objectType].Changed).map(objectUuid => 
      {
        let currentObject = objectContainer[objectType].Changed[objectUuid];
        hasEntry = true
        return {currentObject, objectUuid}
      })

      SortFunction(entries)
      entries.forEach(entry => { changed.push( this.renderChangeObject(entry.currentObject, objectType, entry.objectUuid, noHeader)); })
    }

    if(objectContainer[objectType].Created) 
    {
      let entries = []
      entries = Object.keys(objectContainer[objectType].Created).map(objectUuid => 
      { 
        let currentObject = objectContainer[objectType].Created[objectUuid];
        hasEntry = true
        return {currentObject, objectUuid}
      })

      SortFunction(entries)
      entries.forEach(entry => { created.push( this.renderDeleteCreateObject(entry.currentObject, "Created", objectType, entry.objectUuid, noHeader)); })
    }

    if(objectContainer[objectType].Deleted) 
    {
      let entries = []
      entries = Object.keys(objectContainer[objectType].Deleted).map(objectUuid => 
      {
        let currentObject = objectContainer[objectType].Deleted[objectUuid];
        hasEntry = true
        return {currentObject, objectUuid}
      })

      SortFunction(entries)
      entries.forEach(entry => { deleted.push( this.renderDeleteCreateObject(entry.currentObject, "Deleted", objectType, entry.objectUuid, noHeader)); })
    }

    let sort = [
      {
        text: "ObjectId",
        value: "ObjectId",
        key: "ObjectId",
      },
      {
        text: "ObjectName",
        value: "",
        key: "ObjectName",
      },

    ]

    

    if(hasEntry)
    {
      return (<React.Fragment key={objectType}>
                  
                  {noHeader ? null : 
                  <Header>
                    <Icon link name={hide ? "angle right" : "angle down"} onClick={()=>{this.setState({["__Hide" + objectType]: !hide})}}/>
                    {objectType}
                   
                  </Header>}
                  {
                    hide ? null : 
                    <>
                     {objectType ==="Object" ? <Dropdown
                        button
                        fluid
                        attached="right"
                        className='icon'
                        floating
                        labeled
                        icon='sort'
                        options={sort}
                        text='Sort'
                        value={SortMode}
                        onChange={(e,{value})=>
                        {
                          this.setState({["__Sort" + objectType]: value})
                        }}
                      />: null}
                    {this.renderDivider("edit", "Changed", changed, objectType) }
                    {this.renderDivider("plus", "Created", created, objectType) }
                    {this.renderDivider("minus", "Deleted", deleted, objectType)}
                    </>
                  }

              </React.Fragment>)
    }

    return null;
  }

  renderDivider(icon, name, array, objectType)
  {
    if(array.length === 0 )
    {
      return null
    }
    let hide = this.state["__Hide" + objectType + name] === true

    let checked = true

    let t = this.getDefiningDiffs()[objectType]?.[name]
    if (t) {
      if (name === "Created" || name === "Deleted") {
        for (let i of Object.values(t)) {
          if (!i.Apply) {
            checked = false
            break;
          }
        }
      } else {  //changed
        let checkForApply = (toCheck) => {
          let ret = false
          for(let [k, v] of Object.entries(toCheck)){
            if(k === "Apply"){
              return v
            }else if(typeof v == "object"){
              if(checkForApply(v)){
                ret = true
                break;
              }
            }
          }
          return ret
        }

        for(let i of Object.values(t)){
          if(typeof i === "object"){
            if(!checkForApply(i)){
              checked = false
              break;
            }
          }
        }

      }
    }


    return(
      <>
      <Divider horizontal>
      <Header as='h4' style={{display: "flex", flexDirection: "row"}}>
        <Icon link name={hide ? "angle right" : "angle down"} onClick={()=>{this.setState({["__Hide" + objectType + name]: !hide})}}/>
        
        <Icon style={{marginLeft: "px"}} name={icon} />
        {LocalizedStrings[name]}
        </Header>

        </Divider>
        <Table fixed style={{border: "none"}}>
          <Table.Cell />
          <Table.Cell />
          <Table.Cell />
          <Table.Cell />
          <Table.Cell>
            {objectType !== "Geometries" ? <Checkbox toggle checked={checked} onChange={(e, { checked }) => {
          if(name === "Created" || name === "Deleted")
          {
            this.setApplyForDeleteAdd(objectType, name, checked)
          }
          else if(name === "Changed")
          {
            this.setApplyValueChangeForType(objectType, checked)
          }
          
            }} /> : null}
            
          </Table.Cell>
        </Table>
        
      {hide ? null : array}
      </>
    )
  }

  renderIdentHeaderCellChange = (collapsed, ident, currentObject) =>
  {
    let onChange = () => 
    {
      this.setState({
        Collapsed :
        {
          ...this.state.Collapsed,
          [ident] : this.props.commitOverview ? !collapsed : collapsed
        }
      })
    }
    return(
      <Table.HeaderCell key={currentObject.ObjectId}>
        <Icon name={ collapsed ?"angle right": "angle down"} onClick={onChange}/>
        {currentObject.ObjectName}
        {currentObject.ObjectId !== undefined && currentObject.ObjectId !== "" ? <Label>{currentObject.ObjectId}</Label> : null}
    </Table.HeaderCell>
    )
  }

  renderIdentHeaderCellDeleteCreate = (collapsed, ident, completeObject) =>
  {
    let onChange = () => 
    {
      this.setState({
        Collapsed :
        {
          ...this.state.Collapsed,
          [ident] : this.props.commitOverview ? !collapsed : collapsed
        }
      })
    }
    return(
      <Table.HeaderCell>
        <Icon name={ collapsed ?"angle right": "angle down"} onClick={onChange}/>
        {completeObject.Name}
    </Table.HeaderCell>
    )
  }

  renderChangeObject(currentObject, objectType, objectUuid, noFilter)
  {
    const { onlyDisplay } = this.props


    if(!noFilter && !this.filterBySearch(currentObject))
    {
      return null
    }

    let properties = Object.keys(currentObject.Changes).map(propertyName => {return propertyName})
    properties.sort()

    let collapsed  = !this.state.Collapsed[objectType+objectUuid]

    if(this.props.commitOverview) { collapsed  = !collapsed }

    //------------------------------------------------------------------------------------------------------------------------
    // Render Changed Geometries
    let geometriesChangedTable = null
    if(currentObject.Changes && currentObject.Changes.Geometries)
    {
      geometriesChangedTable = this.renderObjectType(currentObject.Changes, "Geometries", true /* No Header*/ )
    }

    let checkForApply = (toCheck) => {
      let ret = false
      for(let [k, v] of Object.entries(toCheck)){
        if(k === "Apply"){
          return v
        }else if(typeof v == "object"){
          if(checkForApply(v)){
            ret = true
            break;
          }
        }
      }
      return ret
    }

    return (
      <>
      <Table fixed size="small" compact striped key={objectUuid+"Change"}>
        <Table.Header>
          <Table.Row>
            {this.renderIdentHeaderCellChange(collapsed, objectType+objectUuid, currentObject)}
          <Table.HeaderCell>
           {LocalizedStrings.ExistingValue}
          </Table.HeaderCell>
          <Table.HeaderCell>
          {LocalizedStrings.NewValue}
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Label tag color="blue">
              {LocalizedStrings.Changed}
            </Label>
          </Table.HeaderCell>
          {!onlyDisplay ? <Table.HeaderCell>
          <Checkbox toggle checked={checkForApply(currentObject)} onChange={(e, {checked})=>{ this.setApplyValueChangeForTypeUUID(objectType,objectUuid, checked) }}/>
          </Table.HeaderCell> : null} 
          </Table.Row>
        </Table.Header>
        <Table.Body>
        {collapsed ? null : properties.map(propertyName => {
          let changeObject = currentObject.Changes[propertyName];
          let result = [];
          if (changeObject.Value)
          {
            let propIdent = propertyName
            if (changeObject.PropertyName_Display) { propertyName = changeObject.PropertyName_Display; }
            else 
            {
              let loc = LocalizedStrings_ObjectProperties[propertyName]
              if(loc)
              {
                propertyName = loc
              }
            }
            let existingValues
            let newValues

            let forceCompare = false

            // differentiate between objects and values
            if ( [propertyDiffTypes.UUIDOBJECTLIST, propertyDiffTypes.UUIDOBJECT].includes(changeObject.Value.PropertyDiffType) )
            {
              existingValues = this.getPropertyValueAsStringArray(changeObject.Value.ExistingObjectNames);
              newValues      = this.getPropertyValueAsStringArray(changeObject.Value.NewObjectNames);
            }   
            else if ( [propertyDiffTypes.HierarchicalObjects].includes(changeObject.Value.PropertyDiffType) )
            {
              existingValues = changeObject.Value.ExistingValueText
              newValues      = changeObject.Value.NewValueText
              forceCompare   = true
            }  
            else{
              //If the value is an object, we split it up and generate a string array to be aple to display the object
              existingValues = this.getPropertyValueAsStringArray(changeObject.Value.ExistingValue);
              newValues      = this.getPropertyValueAsStringArray(changeObject.Value.NewValue);
              
              if (changeObject.Value.ExistingValue_Display) { existingValues = this.getPropertyValueAsStringArray(changeObject.Value.ExistingValue_Display);}
              if (changeObject.Value.NewValue_Display)      { newValues = this.getPropertyValueAsStringArray(changeObject.Value.NewValue_Display);}
            }

            if (existingValues !== newValues || forceCompare)
            {
              result.push(<Table.Row key={objectUuid+propertyName}>
                            <Table.Cell>
                              {propertyName}
                            </Table.Cell>
                            <DiffByProperty newValues={newValues} existingValues={existingValues} diffType={changeObject.Value.PropertyDiffType}/>
                            <Table.Cell/>
                            {!onlyDisplay ? <Table.Cell>
                              <Checkbox checked={changeObject.Value.Apply} toggle onChange={this.setApplyValueChange(objectType, objectUuid, propIdent)}/>
                            </Table.Cell> : null}
                          </Table.Row>)
            }
          }
          if (changeObject.Presets)
          {
            let presetChanges = changeObject.Presets;
            Object.keys(presetChanges).forEach(presetUuid => {
              let currentPresetChange = presetChanges[presetUuid];

              let existingValues = this.getPropertyValueAsStringArray(currentPresetChange.ExistingValue);
              let newValues = this.getPropertyValueAsStringArray(currentPresetChange.NewValue);

              if (currentPresetChange.ExistingValue_Display) { existingValues = this.getPropertyValueAsStringArray(currentPresetChange.ExistingValue_Display); }
              if (currentPresetChange.NewValue_Display) { newValues = this.getPropertyValueAsStringArray(currentPresetChange.NewValue_Display); }

              result.push(<Table.Row key={objectUuid+propertyName+presetUuid}>
                <Table.Cell>
                  <Label tag color="pink">
                    {propertyName}
                  </Label>
                </Table.Cell>
                <Table.Cell>
                  {existingValues.map(val => <div key={val}>{val}</div>)}
                </Table.Cell>
                <Table.Cell>
                  {newValues.map(val => <div key={val}>{val}</div>)}
                </Table.Cell>
                <Table.Cell>
                </Table.Cell>
                {!onlyDisplay ? <Table.Cell>
                  <Checkbox checked={currentPresetChange.Apply} toggle onChange={this.setApplyPresetValueChange(objectType, objectUuid, presetUuid, propertyName)}/>
                </Table.Cell> : null}
              </Table.Row>)
            })
          }

          return result;
        })}
      </Table.Body>
      </Table>
      {
        !collapsed && geometriesChangedTable ? 
        <div style={{marginLeft: "5em"}}>
        <Header>{LocalizedStrings.Geometries}</Header>
        {geometriesChangedTable}
      </div>
      : 
      null
      }
      
      </>)
  }

  renderDeleteCreateObject(currentObject, changeType, objectType, objectUuid, noFilter)
  {
    const { onlyDisplay } = this.props

    if(!noFilter && !this.filterBySearch(currentObject))
    {
      return null
    }

    let completeObject = currentObject.Object;

    let collapsed  = !this.state.Collapsed[objectType+objectUuid]

    return (
      <Table fixed size="small" compact striped key={currentObject.UUID}>
        <Table.Header>
          <Table.Row>
          {this.renderIdentHeaderCellDeleteCreate(collapsed, objectType+objectUuid, completeObject)}
          <Table.HeaderCell/>
          <Table.HeaderCell/>
          <Table.HeaderCell>
            <Label color={changeType === "Created" ? "green" : "red"} tag>
              {changeType === "Created" ? LocalizedStrings.Created : LocalizedStrings.Deleted}
            </Label>
          </Table.HeaderCell>
          {!onlyDisplay ? <Table.HeaderCell>
            <Checkbox toggle disabled={objectType === "Geometries"} checked={currentObject.Apply} onChange={this.setApply(objectType, objectUuid, changeType)}/>
          </Table.HeaderCell> : null}
          </Table.Row>
        </Table.Header>
          {collapsed ? null : this.renderObjectProperty(completeObject, "Name")}
          {collapsed ? null : this.renderObjectProperty(completeObject, "Layer")}
          {collapsed ? null : this.renderObjectProperty(completeObject, "Class")}
          {collapsed ? null : this.renderObjectProperty(completeObject, "User")}
          {collapsed ? null : this.renderObjectProperty(completeObject, "FixtureId")}
          {collapsed ? null : this.renderObjectProperty(completeObject, "LinkedFixtureType")}
          {collapsed ? null : this.renderObjectProperty(completeObject, "CurrentMode")}
        </Table>);
  }
  renderObjectProperty(currentObject, Property)
  {
    const { onlyDisplay } = this.props

    if( ! (Property in currentObject)) { return null }

    let entry = currentObject[Property]
    let text;

    if(entry instanceof Array)
    {
      text = entry[0].Default
    }
    else
    {
      text = entry
    }

    return(<Table.Row key={currentObject.UUID+Property}>
    <Table.Cell>
      {Property}
    </Table.Cell>
    <Table.Cell>
      {text}
    </Table.Cell>
    <Table.Cell/>
    <Table.Cell/>
    {!onlyDisplay ? <Table.Cell/> : null}
  </Table.Row>)
  }

  getPropertyValueAsStringArray(value)
  {
    if (value === undefined) { return undefined }
    let result = [];
    if (value instanceof Object) { result = Object.keys(value).map(valName => `${valName}:${value[valName]}`) }
    else { result = [value.toString()]; }
    return result;
  }

  setApply = (objType, uuid, changeType) => (_ , {checked}) => this.setState({
    differences: {
      ...this.getDefiningDiffs(),
      [objType]: {
        ...this.getDefiningDiffs()[objType],
        [changeType]: {
          ...this.getDefiningDiffs()[objType][changeType],
          [uuid]: {
            ...this.getDefiningDiffs()[objType][changeType][uuid],
            Apply: checked
          }
        }
      } 
    }
  })

  setApplyForDeleteAdd = (objType, changeType, checked) => 
  {
    let newObject = {}

    let oldObject = this.getDefiningDiffs()[objType][changeType]
    for(const [key] of Object.entries(oldObject))
    {
      newObject[key] = {...oldObject[key], Apply: checked}
    }


    this.setState({
    differences: {
      ...this.getDefiningDiffs(),
      [objType]: {
        ...this.getDefiningDiffs()[objType],
        [changeType]: newObject
      } 
    }
  })
}

ChangeApply = (objectOrArray, apply) => 
{
    if(typeof objectOrArray === "object") 
    {
        for (const key in objectOrArray) 
        {
            if (objectOrArray[key] !== undefined) 
            {   
                if(key === "Apply")
                {
                    objectOrArray[key] = apply
                }
                else 
                {
                    objectOrArray[key] = this.ChangeApply(objectOrArray[key], apply)
                }
            }
        }
    }
    return objectOrArray
}

setApplyValueChangeForType = (objType, checked)=>
{
  let oldChanged = this.getDefiningDiffs()[objType].Changed
  
  let parsed = this.ChangeApply( JSON.parse(JSON.stringify(oldChanged)), checked )


  this.setState({
    differences: {
      ...this.getDefiningDiffs(),
      [objType]: {
        ...this.getDefiningDiffs()[objType],
        Changed: {
          ...parsed
        }
      }
    }
  })
}

setApplyValueChangeForTypeUUID = (objType, uuid, checked)=>
{
  let oldChanged = this.getDefiningDiffs()[objType].Changed

  let parsed = this.ChangeApply( JSON.parse(JSON.stringify(oldChanged[uuid])), checked )


  this.setState({
    differences: {
      ...this.getDefiningDiffs(),
      [objType]: {
        ...this.getDefiningDiffs()[objType],
        Changed: {
          ...oldChanged,
          [uuid]: parsed
        }
      }
    }
  })
}

  setApplyValueChange = (objType, uuid, propertyName) => (_, {checked}) => this.setState({
    differences: {
      ...this.getDefiningDiffs(),
      [objType]: {
        ...this.getDefiningDiffs()[objType],
        Changed: {
          ...this.getDefiningDiffs()[objType].Changed,
          [uuid]: {
            ...this.getDefiningDiffs()[objType].Changed[uuid],
            Changes: {
              ...this.getDefiningDiffs()[objType].Changed[uuid].Changes,
              [propertyName]: {
                ...this.getDefiningDiffs()[objType].Changed[uuid].Changes[propertyName],
                Value: {
                  ...this.getDefiningDiffs()[objType].Changed[uuid].Changes[propertyName].Value,
                  Apply: checked
                }
              }
            }
          }
        }
      }
    }
  })

  setApplyPresetValueChange = (objType, objectUuid, presetUuid, propertyName) => (_, {checked}) => this.setState({
    differences: {
      ...this.getDefiningDiffs(),
      [objType]: {
        ...this.getDefiningDiffs()[objType],
        Changed: {
          ...this.getDefiningDiffs()[objType].Changed,
          [objectUuid]: {
            ...this.getDefiningDiffs()[objType].Changed[objectUuid],
            Changes: {
              ...this.getDefiningDiffs()[objType].Changed[objectUuid].Changes,
              [propertyName]: {
                ...this.getDefiningDiffs()[objType].Changed[objectUuid].Changes[propertyName],
                Presets: {
                  ...this.getDefiningDiffs()[objType].Changed[objectUuid].Changes[propertyName].Presets,
                  [presetUuid]: {
                    ...this.getDefiningDiffs()[objType].Changed[objectUuid].Changes[propertyName].Presets[presetUuid],
                    Apply:  checked
                  }
                }
              }
            }
          }
        }
      }
    }
  })

  setDiffs = (diffs) => {
    this.setState({
      differences: diffs
    })
  }

  getDiffs = () => {
    return this.getDefiningDiffs()
  }
}

export default DiffTable