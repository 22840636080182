//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import LongPressMenu from './LongPressMenu';
import LocalizedStrings from "../../localization/RightClickMenu";
import {EMPTY_UUID, IsElectronContext, IsRunningAsBrowser, IsVectorworksContext, OBJECT_TYPE_Fixture} from '../../util/defines'

import { globalCallbacks } from "../../util/callback";

//---------------------------------------------------------------------
// Menu Attached Data
let gLrUUID;
let gRemovePresetUUID;
let gRemovePropertyName;

let gRenderer3DPoint = {
    Px: 0,
    Py: 0,
    Pz: 0
}

async function GetObjectMenuDef(objectUuid, geometryUuid)
{
    let lrObject = await window.LR_GetObject({UUID: objectUuid})
    if (!lrObject) { lrObject = {}}
    let lrChild = await window.LR_GetFirstChild({UUID: objectUuid})

    let objectHasChild = Object.entries(lrChild).length > 0

    let result = []

    result.push({label: LocalizedStrings.Add, subMenu: [
        {label: LocalizedStrings.AssemblyGroup, cmd: "LR_AddNewObject", arg: {ResourceType: 0, Px: 0, Py: 0, Pz: 0, ParentUUID: objectUuid}}
    ]})
    result.push({type: "separator"})
    result.push({label: LocalizedStrings.RenameObject, cmd:"LR_ActivateInputField", arg:{HTMLInputId: "_OIP_Name"} ,             })
    result.push({label: LocalizedStrings.RenameObjectId, cmd:"LR_ActivateInputField", arg:{HTMLInputId: "_OIP_ObjectId"} ,             })
    result.push({type: "separator"})
    result.push({label: LocalizedStrings.SelectAll, cmd:"LR_SelectAll", arg:{Value: true} ,             })
    result.push({type: "separator"})
    result.push({label: LocalizedStrings.GroupSelected,    cmd:"LR_GroupSelected", arg:{} ,             })
    result.push({label: LocalizedStrings.MoveToAssemblyGroup, cmd:"LR_MoveToAssemblyGroup", arg:{} ,enabled: !!lrObject.UUID})
    result.push({label: LocalizedStrings.PackInto, cmd:"LR_PackObjectsIntoContainer", arg:{} ,             })
    result.push({label: LocalizedStrings.DisolveGroup,    cmd:"LR_DisolveGroup", arg:{UUID: gLrUUID}  , enabled: !!lrObject.UUID})
    result.push({type: "separator"})

    let patchMenu = {label: LocalizedStrings.Patch, subMenu: []}
        patchMenu.subMenu.push({label: LocalizedStrings.PowerPatchObjects,cmd:"LR_PatchChildObjects", arg:{UUID:objectUuid} , enabled: objectHasChild})
        patchMenu.subMenu.push({label: LocalizedStrings.DataPatchObjects,cmd:"LR_DataPatchChildObjects", arg:{UUID: objectUuid} , enabled: objectHasChild})
        patchMenu.subMenu.push({label: LocalizedStrings.PatchSelectedObject,cmd:"LR_PatchSelectedObjects", arg:{}  , enabled: lrObject.IsElectrical})
        patchMenu.subMenu.push({label: LocalizedStrings.DataPatchSelectedObjects, cmd:"LR_DataPatchSelectedObjects", arg:{},  enabled: lrObject.ObjectType === OBJECT_TYPE_Fixture})
        patchMenu.subMenu.push({label: LocalizedStrings.ResolveOutputUsage, cmd:"LR_ResolveMultipleOutputUsage", arg:{UUID: objectUuid},  enabled: lrObject.IsElectrical})
    result.push(patchMenu)
    result.push({type: "separator"})

    result.push({label: LocalizedStrings.ChangeDefiningResource,  cmd:"LR_ShowChangeFixtureType", arg:{UUID: objectUuid}  , enabled: lrObject.DefiningResource && Object.entries(lrObject.DefiningResource).length > 0})
    result.push({type: "separator"})

    let sortMenu = {label: LocalizedStrings.Sort, subMenu: []}
    sortMenu.subMenu.push({label: LocalizedStrings.SortFixtureById,       cmd:"LR_SortFixtureByProperty", arg:{Parent: objectUuid, Ident: "FixtureId"}  , enabled: !!lrObject.UUID})
    sortMenu.subMenu.push({label: LocalizedStrings.SortFixtureByName,     cmd:"LR_SortFixtureByProperty", arg:{Parent: objectUuid, Ident: "Name"}  , enabled: !!lrObject.UUID})
    sortMenu.subMenu.push({label: LocalizedStrings.SortFixtureByPosition, cmd:"LR_SortFixturesByPositionOnStruct", arg:{Root: objectUuid}  , enabled: !!lrObject.UUID})
    sortMenu.subMenu.push({label: LocalizedStrings.SortByObjectId,        cmd:"LR_SortFixtureByProperty", arg:{Parent: objectUuid, Ident: "ObjectId"}  , enabled: !!lrObject.UUID})
    sortMenu.subMenu.push({label: LocalizedStrings.SortByResourceType,    cmd:"LR_SortFixtureByProperty", arg:{Parent: objectUuid, Ident: "ResourceType"}  , enabled: !!lrObject.UUID})
    sortMenu.subMenu.push({type: "separator"})
    result.push(sortMenu)

    result.push({label: LocalizedStrings.CreateWorksheetMenu,    cmd:"LR_ShowCreateWorksheetDialog", arg:{UUID: objectUuid}  ,enabled: !!lrObject.UUID})
    result.push({label: LocalizedStrings.CreateDrawingNote,    cmd:"LR_ShowCreateDrawingNote", arg:{UUID: objectUuid}  ,enabled: !!lrObject.UUID})
    result.push({type: "separator"})
    result.push({label: LocalizedStrings.Delete,  cmd:"LR_DeleteObject", arg:{UUID: objectUuid, IfSelectedAllSelected: true} } )
    result.push({label: LocalizedStrings.Copy,  cmd:"LR_CopyObject", arg:{}  ,                       })
    result.push({label: LocalizedStrings.Cut,    cmd:"LR_CutObject", arg:{}  ,                          })
    result.push({label: LocalizedStrings.Paste,    cmd:"LR_PasteObject", arg:{}  ,                       })
    result.push({label: LocalizedStrings.DuplicateObject,     cmd:"LR_DuplicateObject", arg:{UUID:objectUuid}  ,enabled: !!lrObject.UUID})
    result.push({type: "separator"})
    result.push({label: LocalizedStrings.ArrayModifier,   cmd:"LR_ShowArrayModifierDialog", arg:{}  , enabled: !!lrObject.UUID})
    result.push({type: "separator"})

    result.push({label: LocalizedStrings.SelectConnectedStruct,    cmd:"LR_SelectConnectedStructure", arg:{Object: objectUuid}, enabled: lrObject.HasStructures})
    result.push({label: LocalizedStrings.PlaceToStructure,         cmd:"LR_RunPlaceToStructure", arg:{ParentObject: gLrUUID}, enabled: lrObject.ResourceType === 0})// 0 is for assembly groups
    result.push({label: LocalizedStrings.PlaceToSelectedStructure, cmd:"LR_RunPlaceToStructure", arg:{UseSelected: true}})

    if(geometryUuid)
    {
        result.push({
            label: LocalizedStrings.ShowStructuralResult, 
            cmd:"LR_ShowResultsForSelection", arg:{Object: objectUuid, Geometry: geometryUuid}
        })
    }

    result.push({type: "separator"})

    result.push({label: LocalizedStrings.SelectChildren,   cmd:"LR_SelectChildren", arg:{UUID:gLrUUID}  ,    enabled: objectHasChild})
    result.push({label : LocalizedStrings.CollapseChildren,  cmd:"LR_CollapseChildren", arg:{UUID: gLrUUID}  ,     enabled: objectHasChild})
    result.push({type: "separator"})
    
    result.push({label: LocalizedStrings.ChangeAssemblyOrigin, cmd:"LR_OpenChangeOrigin", arg:{UUID: gLrUUID}, enabled: lrObject.CurrentAssemblyGroup !== EMPTY_UUID})
    
    result.push({label: LocalizedStrings.AutoNumberFixtureId,    cmd:"LR_OpenFixtureNumbering", arg:{UUID: gLrUUID}})
    result.push({type: "separator"})
    result.push({label: LocalizedStrings.ReportSymbolOnline,    cmd:"LR_MakeFeedbackInApp", arg:{ID: "4568668913532928"}, enabled: !!lrObject.UUID})

    return result;
}

let removePresetEntryMenuDef = 
[
    {icon: "a", label: LocalizedStrings.RemovePresetEntry, cmd:"LR_RemovePresetEntry", arg:{PresetUUID: gRemovePresetUUID, PropertyName: gRemovePropertyName}}
]

let rendererMenuDef = 
[
    {
        icon: "a", label: LocalizedStrings.Add, 
        subMenu: [
            {icon: "b", label: LocalizedStrings.AssemblyGroup,  cmd:"LR_AddNewObject", arg:{...gRenderer3DPoint, ResourceType: 0}}
        ]
    },
    
]

let CopyPaste = IsElectronContext() ? 
[
    
    {role: "copy", label: "Copy"},
    {role: "paste", label: "Paste"},
    {role: "cut", label: "Cut"},
    
]:
[
    {cmd: "copy", label: "Copy"},
    {cmd: "cut", label: "Cut"},
]

//-----------------------------------------------------------------------------
// The component for the TreeControl
class RightClickMenu extends Component 
{   
  render() 
  {
    return <LongPressMenu/>;
  }


  componentDidMount = () =>
  {

    window.addEventListener('contextmenu', (e) => 
    {
        if(IsVectorworksContext() || IsRunningAsBrowser())
        {
            e.preventDefault()
        }

      //------------------------------------------------------------------------
      gLrUUID = this.getAttributeFromTarget(e.target, "data-lrhandle");      
      if(gLrUUID)
      {
        GetObjectMenuDef(gLrUUID).then( theMenuDef => {
        if(IsElectronContext())
        {
            window.LR_E_RightClickMenu(theMenuDef)
            e.preventDefault()
        }
        else
        {
            let click = e
            if(e.changedTouches)    { click = e.changedTouches[0] }
            globalCallbacks.showContextMenu( theMenuDef, click);
            e.preventDefault()
        }
        })
      }
    //------------------------------------------------------------------------
      let PropertyString = this.getAttributeFromTarget(e.target, "data-propertyhandle");      
      if(PropertyString)
      {
        if(IsElectronContext())
        {
            window.LR_E_RightClickMenu([
                {icon: "a", label: LocalizedStrings.RenameProperty, cmd:"LR_RenameProperty", arg: JSON.parse(PropertyString)},
                {icon: "a", label: LocalizedStrings.ResetPropertyName, cmd:"LR_ClearPropertyName", arg: JSON.parse(PropertyString)},
                {icon: "a", label: LocalizedStrings.RemoveColumn, cmd:"LR_RemoveColumn", arg: JSON.parse(PropertyString)}
            ])

            e.preventDefault()
        }
        else
        {
            let click = e
            if(e.changedTouches)    { click = e.changedTouches[0] }
            globalCallbacks.showContextMenu( [
                {icon: "a", label: LocalizedStrings.RenameProperty, cmd:"LR_RenameProperty", arg: JSON.parse(PropertyString)},
                {icon: "a", label: LocalizedStrings.ResetPropertyName, cmd:"LR_ClearPropertyName", arg: JSON.parse(PropertyString)},
                {icon: "a", label: LocalizedStrings.RemoveColumn, cmd:"LR_RemoveColumn", arg: JSON.parse(PropertyString)}
            ], click);

            e.preventDefault()
            
        }
      }
      

      gRemovePresetUUID   = this.getAttributeFromTarget(e.target, "data-removepreset");
      gRemovePropertyName = this.getAttributeFromTarget(e.target, "data-removepropname");
      if (gRemovePresetUUID) 
      {
        if(IsElectronContext())
        {
            window.LR_E_RightClickMenu(removePresetEntryMenuDef)
            e.preventDefault()
        }
        else
        {
            let click = e
            if(e.changedTouches)    { click = e.changedTouches[0] }
            globalCallbacks.showContextMenu( removePresetEntryMenuDef, click );
            e.preventDefault()
        }


      }

      if (this.getAttributeFromTarget(e.target, "data-login")) 
      {
        if(IsElectronContext())
        {
            window.LR_E_RightClickMenu(CopyPaste)
            e.preventDefault()
        }
        else
        {
            let click = e
            if(e.changedTouches)    { click = e.changedTouches[0] }
            globalCallbacks.showContextMenu( CopyPaste, click );
            e.preventDefault()
        }


      }

    }, false);


    globalCallbacks.openRendererRightClickMenu = async (e, pointInRenderer, clickedObject, clickedGeometry) => 
    {
        gRenderer3DPoint = pointInRenderer

        if (clickedObject)
        {
            if (clickedObject.lrUUID)
            {
                gLrUUID = clickedObject.lrUUID
            }
            else if (clickedObject.lrGeometryUUID)
            {
                gLrUUID = clickedObject.lrGeometryUUID
            }
            else
            {
                gLrUUID = undefined
            }
        }
        else
        {
            gLrUUID = undefined
        }
        let geometryUuuid = undefined
        if (clickedGeometry)
        {
            geometryUuuid = clickedGeometry.lrGeometryUUID
        }


        let openingMenuDef = [...rendererMenuDef]

        if (gLrUUID)
        {
            openingMenuDef = await GetObjectMenuDef(gLrUUID, geometryUuuid)
        }

        if (IsElectronContext())
        {
            window.LR_E_RightClickMenu(openingMenuDef)
        }
        else
        {
            let click = e
            if(e.changedTouches)    { click = e.changedTouches[0] }


            globalCallbacks.showContextMenu( openingMenuDef, click );

        }

        e.stopPropagation()
    }

    globalCallbacks.openRendererBeamModeRightClickMenu = async (e, clickedObject) => 
    {
        gLrUUID = clickedObject && clickedObject.UUID ? clickedObject.UUID : undefined;

        let openingMenuDef = [...rendererMenuDef]

        if (gLrUUID)
        {
            openingMenuDef = await GetObjectMenuDef(gLrUUID, undefined)
        }

        if (IsElectronContext())
        {
            window.LR_E_RightClickMenu(openingMenuDef)
        }
        else
        {
            let click = e
            if(e.changedTouches)    { click = e.changedTouches[0] }

            globalCallbacks.showContextMenu( openingMenuDef, click );
        }

        e.stopPropagation()
    }
  }

  getAttributeFromTarget = (target, attribute) =>
  {
      let tempTarget = target
      let result = undefined
      while (tempTarget && !result)
      {
          result = tempTarget.getAttribute(attribute)
          tempTarget = tempTarget.parentElement
      }
      return result;
  }
}

export default RightClickMenu