//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2024
//----------------------------------------------------------------------------------------------------------------

import React, { Component } from 'react';
import { Grid, Segment, Header, Icon, Button, Divider, Loader, Table, Radio, Form, Container, Placeholder } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/DownloadApp"
import { connect } from 'react-redux';
import { ACTIVE_USER } from '../../redux/redux_defines'
import { hasFetched } from '../../redux/actions/fetch_actions';
const ReactMarkdown = React.lazy(() => import("react-markdown"))

import gfm from 'remark-gfm';
import "./newdownloadapp.css";
const NewNavBar = React.lazy(() => import("../ProductionAssistLandingPage/NewNavBar"));
import IMG_APPSTORE from "../../content/img/appstore.png";
import IMG_GOOGLEPLAY from "../../content/img/googleplay.png";


import fetch from 'cross-fetch';
import NewFooter from '../WebComponents/NewFooter';

const DOWNLOAD_OPTIONS = {
  DESKTOP: 0,
  VW: 1,
  MA: 2,
  AC: 3,
  BRX: 4,
  SKP: 5,
  ZRX: 6
}

//--------------------------------------------------------------------
// LightRightRouter
class NewDownloadApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      versionProduction: {},
      versionBeta: {},
      versions: [],
      checked: {
        windows: DOWNLOAD_OPTIONS.DESKTOP,
        apple: DOWNLOAD_OPTIONS.DESKTOP,
        linux: DOWNLOAD_OPTIONS.DESKTOP
      },
      activeCardIndex: -1,
    }
  }



  componentDidMount = async () => {
    await window.LR_ValidateToken()
    try {
      let response = await fetch("https://development.production-assist.com/api/update/latest");
      let json = await response.json()
      this.setState({ versionProduction: json })
    }
    catch (e) { console.error(e); }

    try {
      let response = await fetch("https://development.production-assist.com/api/update/latestbeta");
      let json = await response.json()

      this.setState({ versionBeta: json })

    }
    catch (e) { console.error(e); }

    try {
      let r1 = await fetch("https://development.production-assist.com/api/update/all");
      let j1 = await r1.json()
      this.setState({ versions: j1 })

    }
    catch (e) { console.error(e); }

  }

  loadReleaseChannel = async (version) => {
    if (!this.state[version]) {
      let response = await fetch("https://development.production-assist.com/api/update/" + version);
      let json = await response.json()
      this.setState({ [version]: json })
    }

  }

  splitMarkdownByHeadings = (markdownText) =>
  {
    const lines = markdownText.split('\n');
    let resultArray = [];
    let currentHeading = null;
    let currentText = '';

    lines.forEach(line => {
        if (line.startsWith('# ')) {
            // If we encounter a new heading, store the previous heading and its corresponding text
            if (currentHeading !== null) {
                resultArray.push([currentHeading, currentText.trim()]);
                currentText = ''; // Reset current text for the next heading
            }
            currentHeading = line;
        } else {
            // Concatenate text between headings
            currentText += line + '\n';
        }
    });

    // Push the last heading and its corresponding text
    if (currentHeading !== null) {
        resultArray.push([currentHeading, currentText.trim()]);
    }

    return resultArray;
  }

  render() {
    let isAdmin = false
    let isBeta = false
    if (hasFetched(this.props.loggedInUser)) {
      isAdmin = this.props.loggedInUser.data.isAdmin
      isBeta = this.props.loggedInUser.data.isBeta
    }

    let splitedmarkdownsArr = [];
    if(this.state.versionProduction.changelog)
    {
        splitedmarkdownsArr = this.splitMarkdownByHeadings(this.state.versionProduction.changelog); 
    }

    return (
      <div className='newdownload-app'>
        <header>
            <NewNavBar/>
            <div className="main-heading">
                <h2>{LocalizedStrings.HeaderRelease + ' ' + this.state.versionProduction.version}</h2>
                <h1>{LocalizedStrings.DownloadHere}</h1>
            </div>
        </header>

        <section className='packages'>
            { this.state.versionProduction.production ? 
                <>
                    {this.renderDownloadStep("windows", this.state.versionProduction.production.winautocad, this.state.versionProduction.production.winbricscad22, this.state.versionProduction.production.winelectron, this.state.versionProduction.production.winvectorworks, this.state.versionProduction.production.winma3,this.state.versionProduction.production.winsketchup,this.state.versionProduction.production.winzwcad, "Windows", false)}
                    {this.renderDownloadStep("apple",this.state.versionProduction.production.macautocad,this.state.versionProduction.production.macbricscad,  this.state.versionProduction.production.macelectron, this.state.versionProduction.production.macvectorworks, this.state.versionProduction.production.macma3,this.state.versionProduction.production.macsketchup,undefined, "macOS", false)}
                    {this.renderDownloadStep("linux", undefined, undefined, this.state.versionProduction.production.linelectron, undefined, undefined,undefined,undefined, "Linux", false)}
                </> : 
                <>
                    {this.renderLoading("windows", "Windows")}
                    {this.renderLoading("apple", "macOS")}
                    {this.renderLoading("linux", "Linux")}
                </>
            }
            <div className="package smalldevice">
                <div className='imgtype'>
                    <Icon name="mobile alternate" style={{ fontSize: "4.5rem"}} />
                </div>
                <div className="topheader">
                    <h4>{LocalizedStrings.ProductionAssistFor}</h4>
                    <h3>{LocalizedStrings.MobileDevice}</h3>
                </div>
                <div className="options">
                    <a href="https://apps.apple.com/app/production-assist" target="_blank" rel="noopener noreferrer">
                        <img className="appstore" src={IMG_APPSTORE} alt="appstore" />
                    </a>
                    <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
                        <img className="googleplay" src={IMG_GOOGLEPLAY} alt="googleplay" />
                    </a>
                </div>
            </div>
        </section>

        <section className='changelog'>
            <h2 className='changelogheader'>{LocalizedStrings.ChangeLogs}</h2>  
            <div className='markdown'>
                {splitedmarkdownsArr.map((el, i) => <Changelog key={i} changelog={el} />)}
            </div>
        </section>

        <Container style={{marginBottom: "5rem"}}>
          {this.state.versionBeta.beta && isBeta ?

            <Segment className='beta' tertiary>
              <Header as="h1" textAlign="center">{LocalizedStrings.HeaderBeta + " " + this.state.versionBeta.version}</Header>
              <Divider />
              <ReactMarkdown remarkPlugins={[gfm]}>{this.state.versionBeta.changelog}</ReactMarkdown>
              <Divider />
              {this.renderDownloadStepBeta(this.state.versionBeta.beta.macelectron, this.state.versionBeta.beta.winelectron, this.state.versionBeta.beta.linelectron, "Desktop App" /*beta*/, true)}
              {this.renderDownloadStepBeta(this.state.versionBeta.beta.macvectorworks, this.state.versionBeta.beta.winvectorworks, undefined, "Production Assist for Vectorworks" /*beta*/, true)}
              {this.renderDownloadStepBeta(undefined, undefined, undefined, "Production Assist for grandMA3" /*beta*/, true)}
              {this.renderDownloadStepBeta(this.state.versionBeta.beta.macautocad, this.state.versionBeta.beta.winautocad, undefined, "Production Assist for AutoCAD" /*beta*/, true)}
              {this.renderDownloadStepBeta(this.state.versionBeta.beta.macbricscad, this.state.versionBeta.beta.winbricscad22, undefined, "Production Assist for BricsCAD" /*beta*/, true)}
              {this.renderDownloadStepBeta(this.state.versionBeta.beta.macsketchup, this.state.versionBeta.beta.winsketchup, undefined, "Production Assist for Sketchup" /*beta*/, true)}
              {this.renderDownloadStepBeta(undefined, this.state.versionBeta.beta.winzwcad, undefined, "Production Assist for ZWcad" /*beta*/, true)}
            </Segment> : null}

          {isAdmin ? this.state.versions.map(v => this.renderVersion(v.version)) : null}
        </Container>
        
        <NewFooter />
      </div>
    );
  }

  renderVersion(versionnumber) {
    let open = this.state[versionnumber + "OPEN"]
    return (
      <Segment tertiary key={versionnumber}>
        <Header as="h1" textAlign="center"><Icon onClick={() => { this.loadReleaseChannel(versionnumber); this.setState({ [versionnumber + "OPEN"]: !open }) }} name={this.state[versionnumber + "OPEN"] ? "angle down" : "angle right"} />{LocalizedStrings.HeaderChannel + " " + versionnumber}</Header>
        {open ? <Divider /> : null}
        <Table celled striped>
          <Table.Body>
            {open ? this.state[versionnumber] ? this.state[versionnumber].updates.map(entry => { return this.renderBin(entry) }) : <Loader /> : null}
          </Table.Body>
        </Table>
      </Segment>)
  }

  renderBin(entry) {
    const date = new Date(Date.parse(entry.date))

    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: "2-digit", minute: "2-digit" };
    return (<Table.Row key={entry._id}>

      <Table.Cell collapsing>
        <Icon name='folder' /> {entry.buildnumber}
      </Table.Cell>
      <Table.Cell>{entry.channel}</Table.Cell>
      <Table.Cell>{entry.type}</Table.Cell>
      <Table.Cell collapsing textAlign='right'>
        {date.toLocaleDateString(undefined, options)}
      </Table.Cell>
      <Table.Cell collapsing textAlign='right'>
        <Button onClick={() => { this.download(entry._id) }} primary><Icon name="download" />{LocalizedStrings.Download}</Button>
      </Table.Cell>
    </Table.Row>)
  }

  renderDownloadStep(iconName, autocad, bricscad, electron, vector, ma3,sketchup,zwcad, entry, showBuildNumber) {
    const getId = (obj) => obj ? obj._id : ""

    const onChange = (checked) => () =>
      this.setState({
        checked: {
          ...this.state.checked,
          [iconName]: checked
        }
      })

    let selected = this.state.checked[iconName]
    let id = ""
    switch (selected) {
      case DOWNLOAD_OPTIONS.DESKTOP: id = getId(electron); break;
      case DOWNLOAD_OPTIONS.VW: id = getId(vector); break;
      case DOWNLOAD_OPTIONS.MA: id = getId(ma3); break;
      case DOWNLOAD_OPTIONS.AC: id = getId(autocad); break;
      case DOWNLOAD_OPTIONS.BRX: id = getId(bricscad); break;
      case DOWNLOAD_OPTIONS.SKP: id = getId(sketchup); break;
      case DOWNLOAD_OPTIONS.ZRX: id = getId(zwcad); break;

      default:
        break;
    }

    return (
      <div className ={`package ${iconName}`}>
        <div className='imgtype'>
            <Icon name={iconName} style={{ fontSize: "3.5rem", color: "#000018!important", backgroundColor: "white" }} />
        </div>

        <div className="topheader">
            <h4>{LocalizedStrings.ProductionAssistFor}</h4>
            <h3>{entry}</h3>
        </div>
      
        <div className='options'>
          <Form>
            <Form.Field>
              <Radio
                label={<label style={{ fontWeight: 'bold', fontSize: '16px' }}>{LocalizedStrings.DesktopRadio}</label>}
                name='radioGroup'
                checked={selected === DOWNLOAD_OPTIONS.DESKTOP}
                onChange={onChange(DOWNLOAD_OPTIONS.DESKTOP)}
              />
            </Form.Field>
            {
              iconName !== "linux" ?
                <Form.Field>
                  <Radio
                      label={<label style={{ fontWeight: 'bold', fontSize: '16px' }}>{LocalizedStrings.VectorworkRadio}</label>}
                    name='radioGroup'
                    checked={selected === DOWNLOAD_OPTIONS.VW}
                    onChange={onChange(DOWNLOAD_OPTIONS.VW)}
                  />
                </Form.Field>
                : null
            }
            {
              iconName !== "linux" ?
                <Form.Field>
                  <Radio
                   label={<label style={{ fontWeight: 'bolder', fontSize: '16px' }}>{LocalizedStrings.GrandMA3Radio}</label>}
                    name='radioGroup'
                    checked={selected === DOWNLOAD_OPTIONS.MA}
                    onChange={onChange(DOWNLOAD_OPTIONS.MA)}
                  />
                </Form.Field>
                : null
            }
            {
              iconName !== "linux" ?
                <Form.Field>
                  <Radio
                   label={<label style={{ fontWeight: 'bolder', fontSize: '16px' }}>{LocalizedStrings.AutoCAD}</label>}
                    name='radioGroup'
                    checked={selected === DOWNLOAD_OPTIONS.AC}
                    onChange={onChange(DOWNLOAD_OPTIONS.AC)}
                  />
                </Form.Field>
                : null
            }
            {
              iconName !== "linux" ?
                <Form.Field>
                  <Radio
                   label={<label style={{ fontWeight: 'bolder', fontSize: '16px' }}>{LocalizedStrings.BricsCAD}</label>}
                    name='radioGroup'
                    checked={selected === DOWNLOAD_OPTIONS.BRX}
                    onChange={onChange(DOWNLOAD_OPTIONS.BRX)}
                  />
                </Form.Field>
                : null
            }
            {
              iconName !== "linux" ?
                <Form.Field>
                  <Radio
                   label={<label style={{ fontWeight: 'bolder', fontSize: '16px' }}>{LocalizedStrings.SketchUp}</label>}
                    name='radioGroup'
                    checked={selected === DOWNLOAD_OPTIONS.SKP}
                    onChange={onChange(DOWNLOAD_OPTIONS.SKP)}
                  />
                </Form.Field>
                : null
            }
            {
              iconName === "windows" ?
                <Form.Field>
                  <Radio
                   label={<label style={{ fontWeight: 'bolder', fontSize: '16px' }}>{LocalizedStrings.ZWCAD}</label>}
                    name='radioGroup'
                    checked={selected === DOWNLOAD_OPTIONS.ZRX}
                    onChange={onChange(DOWNLOAD_OPTIONS.ZRX)}
                  />
                </Form.Field>
                : null
            }
          </Form>
        </div>

        <div className='dload'>
            <button className="download-btn" onClick={() => { this.download(id);}}>{LocalizedStrings.Download} </button>
        </div>
      </div>
    )
  }

  renderDownloadStepBeta(mac, win, lin, entry, showBuildNumber) {
    return (
      <Grid textAlign='center' columns={3} >
        <Grid.Row>
          <Grid.Column size="3">
            <Segment placeholder>
              <Header icon>
                <Icon name='windows' />
                {entry}
                <br />
                {LocalizedStrings.Windows}
                {showBuildNumber ? win ? <><br />{win.buildnumber}<br /></> : <><i>{LocalizedStrings.NoReleaseFound}</i><br /></> : null}

                {(win && showBuildNumber) ? new Date(win.date).toDateString() : null}
              </Header>
              <Button onClick={() => { this.download(win._id) }} primary disabled={!win}><Icon name="download" />{LocalizedStrings.Download}</Button>
              {this.renderCopyLink(win?._id)}
            </Segment>
          </Grid.Column >
          <Grid.Column size="3">
            <Segment placeholder>
              <Header icon>
                <Icon name='apple' />
                {entry}
                <br />
                {LocalizedStrings.Mac}
                {showBuildNumber ? mac ? <><br />{mac.buildnumber}<br /></> : <><i>{LocalizedStrings.NoReleaseFound}</i><br /></> : null}
                <br />
                {(mac && showBuildNumber) ? new Date(mac.date).toDateString() : null}
              </Header>
              <Button onClick={() => { this.download(mac._id) }} disabled={!mac} primary><Icon name="download" />{LocalizedStrings.Download} </Button>
              {this.renderCopyLink(mac?._id)}
            </Segment>
          </Grid.Column >
          <Grid.Column size="3">
            <Segment placeholder>
              <Header icon>
                <Icon name='linux' />
                {entry}
                <br />
                {LocalizedStrings.Ubunto}
                {showBuildNumber ? lin ? <><br />{lin.buildnumber}<br /></> : <><i>{LocalizedStrings.NoReleaseFound}</i><br /></> : null}
                {(lin && showBuildNumber) ? new Date(lin.date).toDateString() : null}
              </Header>
              <Button onClick={() => { this.download(lin._id) }} disabled={!lin} primary ><Icon name="download" />{LocalizedStrings.Download} </Button>
              {this.renderCopyLink(lin?._id)}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  renderCopyLink(id) {
    return <>
      <Button onClick={() => {
        navigator.clipboard.writeText(`https://development.production-assist.com/api/update/download/${id}`);
      }}><Icon name="copy" /></Button>

    </>
  }

  download = async (id) => {
    let url = "https://development.production-assist.com/api/update/download/" + id

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      "",
    );

    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  renderLoading = (iconName, entry) => {
    return (
      <div className ={`package ${iconName}`}>
        <div className='imgtype'>
          <Icon name={iconName} style={{ fontSize: "3.5rem", color: "#000018" }} />
        </div>
 
        <div className="topheader">
            <h4>{LocalizedStrings.ProductionAssistFor}</h4>
            <h3>{entry}</h3>
        </div>

        <div className='options'>
          <Placeholder fluid color="gray">
            <Placeholder.Line length='medium' />
            <Placeholder.Line length='medium' />
            <Placeholder.Line length='medium' />
          </Placeholder>
        </div>
        <div className='dload'>
            <button className="download-btn">{LocalizedStrings.Download}</button>
        </div>
      </div>
    )
  }
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => {
  return {
    loggedInUser: (state[ACTIVE_USER]),
  };
}

class Changelog extends Component {
    constructor(props) {
      super(props);
      this.state = {
          showChangelog: false
      }
    }

    render()
    {
        let editedHeaher = this.props.changelog[0]; 
        if(editedHeaher)
        {
            editedHeaher = editedHeaher = editedHeaher.replace("Changelog", "")
        }
       
        return (
            <div className='changelog-item' key={this.props.key}>
                <div className='changelog-header' onClick={() => this.setState({showChangelog: !this.state.showChangelog})}>
                    <ReactMarkdown remarkPlugins={[gfm]}>{editedHeaher}</ReactMarkdown>
                    <span className='showhire'>{ this.state.showChangelog ? <Icon name='chevron up' size='large' /> : <Icon name='chevron down' size='large' />}</span>
                </div>
                <div className={`${this.state.showChangelog ? "showchangelog" : "hirechangelog"}`}>
                    <ReactMarkdown remarkPlugins={[gfm]}>{this.props.changelog[1]}</ReactMarkdown>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(NewDownloadApp)


