//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import LocalizedStrings from "../../../localization/TableViewComponent";
import { BASE_UNIT_COLOR_OBJECT, BASE_UNIT_LENGTH, BASE_UNIT_NUMBER, BASE_UNIT_SELECTABLE, BASE_UNIT_WEIGHT, EMPTY_UUID } from '../../../util/defines';
import UnitInput from '../../Basics/BasicUnitInput';
import DynamicTable from '../../Basics/DynamicTableView';

import { globalCallbacks as mainCB } from '../../../util/callback';
import { globalCallbacks as mockCB } from '../../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB




const cellIdnetifier =  [{ident: "TrussName", label: LocalizedStrings.TrussName}, 
                        {ident: "Count", label: LocalizedStrings.TrussCount}, 
                        {ident: "Weight", label: LocalizedStrings.TrussWeight, unit: BASE_UNIT_WEIGHT}, 
                        {ident: "Length", label: LocalizedStrings.TrussLength, unit: BASE_UNIT_LENGTH}, 
                        {ident: "Loads", label: LocalizedStrings.TrussLoad, unit: BASE_UNIT_WEIGHT}, 
                        {ident: "TrimmHeight", label: LocalizedStrings.TrimmHeight, unit: BASE_UNIT_LENGTH}, 
                        {ident: "ColorCode", label: LocalizedStrings.ColorCodeObject}, 
                        {ident: "Layer", label: LocalizedStrings.Layer}]

class GraphList extends Component
{


    constructor(prop)
    {
        super(prop);
        this.state = 
        {
            Structures: [],
        }
        
        //////////////////////////////////

        this.setUpCallbacks();
    }

    componentDidMount()
    {
        if (globalCallbacks.updateGraphList) { globalCallbacks.updateGraphList(); }
    }

    componentDidUpdate(oldProps)
    {
        if (oldProps.rootObject !== this.props.rootObject)
        {
            if (globalCallbacks.updateGraphList) { globalCallbacks.updateGraphList(); }
        }
    }

    render()
    {
        let cellRendering = (entry, rowIndex) =>
        {
            let renderSelect = (type, UUID, data) => {
                return (<UnitInput 
                            baseUnit={BASE_UNIT_SELECTABLE}
                            value={UUID}
                            options={data}
                            onlyShowOnEdit
                            updateData={(value)=>{ window.LR_SetMultipleObjects(entry.ObjectsOnStructure.map(uuid => ({UUID: uuid, [type]: value}))) }}/>)
            }

            return {
                TrussName: <>{entry.Name}</>,
                Count: <></>,
                Weight: <UnitInput value={entry.Weight} transparent readOnly baseUnit={BASE_UNIT_WEIGHT} label={false}/>,
                TrimmHeight: <UnitInput value={entry.TrimHeight} transparent readOnly baseUnit={BASE_UNIT_LENGTH} label={false}/>,
                Loads: <UnitInput value={entry.Loads} transparent readOnly baseUnit={BASE_UNIT_WEIGHT} label={false}/>,
                ColorCode: renderSelect("ColorCode", entry.ColorCodeObject.UUID, this.state.colorCodes),
                Layer: renderSelect("Layer", entry.LayerObject.UUID, this.state.layers),
                Length: <UnitInput  baseUnit = {BASE_UNIT_LENGTH}
                                    value    = {entry.Length}
                                    label    = {false}
                                    transparent
                                    readOnly/>
            }
        }

        let extraCellRendering = (parentEntry, entry, rowIndex) =>
        {

            let renderSelect = (type, UUID, data) => {
                return (<UnitInput 
                            baseUnit={BASE_UNIT_SELECTABLE}
                            value={UUID}
                            options={data}
                            onlyShowOnEdit
                            updateData={(value)=>{window.LR_SetMultipleObjects(entry.Objects.map(uuid => ({UUID: uuid, [type]: value})))}}/>)
            }

            if (this.props.structureGroupByObject)
            {
                return {
                    TrussName: entry.Name,
                    Weight: <UnitInput transparent readOnly baseUnit={BASE_UNIT_WEIGHT} value={entry.Weight} label={false}/>,
                    Count: <UnitInput transparent readOnly baseUnit={BASE_UNIT_NUMBER} value={entry.Count}/>,
                    TrimmHeight: <></>,
                    Loads: <></>,
                    ColorCode: renderSelect("ColorCode", entry.ColorCode.UUID, this.state.colorCodes),
                    Layer: renderSelect("Layer", entry.Layer.UUID, this.state.layers),
                    Length: <UnitInput transparent readOnly baseUnit={BASE_UNIT_LENGTH} value={entry.StructureLength}/>,
                }
            }
            return {
                TrussName: <UnitInput transparent readOnly value={rowIndex + 1}/>,
                Weight: <></>,
                TrimmHeight: <UnitInput readOnly
                                        baseUnit={BASE_UNIT_LENGTH}
                                        transparent
                                        label={false}
                                        value={Math.min(entry.Start.Z, entry.End.Z)}/>,
                Loads: <></>,
                ColorCode: <></>,
                Layer: <></>,
                Length: <UnitInput  readOnly
                                    baseUnit={BASE_UNIT_LENGTH}
                                    transparent
                                    label={false}
                                    value={this.getDistance(entry.Start, entry.End)}/>,
            }
        }

        let structuresWithExtraData = []
        console.log("This structures: ", this.state.Structures)
        this.state.Structures.forEach(strct => {
            structuresWithExtraData.push({...strct, ExtraData: this.props.structureGroupByObject ? strct.GroupedObjects : strct.StraightLines})
        })

        let sortFunction = (objectA, objectB, ident) =>
        {
            let get = (obj) =>
            {
                return {
                    TrussName: obj.Name,
                    Count: 0,
                    Weight: obj.Weight,
                    TrimmHeight: obj.TrimmHeight,
                    Loads: obj.Loads,
                    ColorCode: obj.ColorCodeObject.Name,
                    Layer: obj.LayerObject.Name,
                    Length: obj.Length,
                }
            }

            return {
                First: get(objectA)[ident],
                Second: get(objectB)[ident]
            }
        }


        return (
            <DynamicTable   headerIdent={cellIdnetifier}
                            cellRender={cellRendering}
                            extraCellRender={extraCellRendering}
                            sortFunction={sortFunction}
                            collapsExtraData
                            headerData={this.props.headerData}
                            tableName="GraphList"
                            pages={this.props.pages}
                            cellData={structuresWithExtraData}
                            showPages={this.props.showPages}
                            fitColumnWidthToContent={this.props.showPages}
                            printMargin={this.props.printMargin}
                            printScale={this.props.worksheetScale}/>
        )
    }

    getDistance(pos1, pos2)
    {
        let xDist = pos1.X - pos2.X
        let yDist = pos1.Y - pos2.Y
        let zDist = pos1.Z - pos2.Z

        return Math.sqrt(xDist ** 2 + yDist ** 2 + zDist ** 2);
    }

    setUpCallbacks()
    {
        globalCallbacks.updateGraphList = async () =>
        {
            let val = await window.LR_GetStructures({RootObject: this.props.rootObject})

            console.log("val", val)

            let res = await window.LR_GetColorCodeObjects()
            let lArray = await window.LR_GetLayers()

            console.log("Update structures: ", val)

            let colorCodes = res.ColorCodeObjects.map(ccObject => 
            {
                return {
                  key: ccObject.UUID,
                  value: ccObject.UUID,
                  text: <UnitInput
                            baseUnit={BASE_UNIT_COLOR_OBJECT}
                            readOnly
                            value={ccObject}
                            style={{minWidth: 100}}
                        />
                };
            })

            let layers = lArray.Layers.map(l => {
                return {
                    key: l.UUID,
                    value: l.UUID,
                    text: l.Name
                }
            })

            this.setState({colorCodes: [
                {
                    key: EMPTY_UUID, 
                    value: EMPTY_UUID, 
                    text:   <UnitInput
                                baseUnit={BASE_UNIT_COLOR_OBJECT}
                                readOnly
                                value={{
                                    Color: [{ X: 0.31273, Y: 0.32902, Z: 21.58 }], // cie grey
                                    Name: LocalizedStrings.NoneColorCode,
                                }}
                                style={{minWidth: 100}}
                            />
                },
                ...colorCodes
            ], Structures: val.Structures, layers: layers})
        }
    }
}

export default GraphList;