
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Header, Tab, Menu, Icon, Container, Popup, Progress, Divider } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/NavigationContainer";
import LayerTable from "./LayerTable";
import ContainerTable from "./ContainerTable";
import ClassTable from "./ClassTable";
import PresetTable from "./PresetTable";
import CaseTable from "./CaseTable";
import UserTable from "./UserTable";
import WorksheetTable from "./WorksheetTable";

import SceneTimeLineComponent from '../SceneTimeLine/SceneTimeLineComponent';
import SelectionGroupTable from './SelectionGroupTable';
import ColorCodeTable from './ColorCodeTable';
import RackTable from './RackTable';
import LoadGroupTable from './LoadGroupTable';
import LoadCombinationTable from './LoadCombinationTable';
import PrintLabelsContainer from './PrintLabelsContainer';
import SavedViewTable from './SavedViewTable';
import InfluenceLineDisplay from './InfluenceLineDisplay';
import DepartmentTable from './DepartmentTable';
import UserTaskOverview from '../UserTaskOverview/UserTaskOverview';
import DrawingErrorTable from './DrawingErrorTable';
import { CheckWorkspace } from '../../util/defines';
import { globalCallbacks } from '../../util/callback';
import StructuralChecks from './StructuralChecks';
import "./Navigation.css"

const NAVIGATION_MIN_WIDTH = 300

class NavigationContainer extends Component 
{
    constructor(props)
    {
        super(props);
        this.menuItemStyle ={
            paddingLeft :"1px",
            paddingRight :"1px",
        }   

        this.isSmall = false
        let workspace = CheckWorkspace()
        

        this.panes = []
        
        if(workspace.Navigation.Classes)    this.panes.push( { menuItem: <Menu.Item style={{...this.menuItemStyle, paddingLeft: 5}}  key={1}><Popup  content={this.renderPopupContent(LocalizedStrings.Classes, LocalizedStrings.ClassesText)} trigger={<Icon name='tags'/>}/></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><ClassTable VWRouterStyle={this.props.VWRouterStyle} small={this.isSmall}/></Tab.Pane> })
        if(workspace.Navigation.Layers)    this.panes.push(     { menuItem: <Menu.Item style={this.menuItemStyle}                       key={0}><Popup  content={this.renderPopupContent(LocalizedStrings.Layers, LocalizedStrings.LayersTLayersTextext)} trigger={<Icon name='sitemap'/>}/></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><LayerTable small={this.isSmall}/></Tab.Pane> })
        if(workspace.Navigation.SelectionGroups)    this.panes.push(     { menuItem: <Menu.Item style={this.menuItemStyle}                       key={5}><Popup  content={this.renderPopupContent(LocalizedStrings.SelectionGroups, LocalizedStrings.SelectionGroupsText)} trigger={<Icon name='object group'/>}/></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><SelectionGroupTable small={this.isSmall}/></Tab.Pane> })
        if(workspace.Navigation.PrintLabels)    this.panes.push(     { menuItem: <Menu.Item style={{...this.menuItemStyle, paddingRight: 5}} key={9}><Popup  content={this.renderPopupContent(LocalizedStrings.PrintLabels, LocalizedStrings.PrintLabelsText)} trigger={<Icon name='print'/>}/></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><PrintLabelsContainer small={this.isSmall}/></Tab.Pane> })
        if(workspace.Navigation.Presets)    this.panes.push(     { menuItem: <Menu.Item style={this.menuItemStyle}                       key={2}><Popup  content={this.renderPopupContent(LocalizedStrings.Presets, LocalizedStrings.PresetsText)} trigger={<Icon name='edit'/>}/></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><PresetTable small={this.isSmall}/></Tab.Pane> })
        if(workspace.Navigation.TimePhases)    this.panes.push(     { menuItem: <Menu.Item style={this.menuItemStyle}                       key={3}><Popup  content={this.renderPopupContent(LocalizedStrings.TimePhases, LocalizedStrings.TimePhasesText)} trigger={<Icon name='time'/>}/></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><SceneTimeLineComponent small={this.isSmall}/></Tab.Pane> })
        if(workspace.Navigation.Users)    this.panes.push(     { menuItem: <Menu.Item style={this.menuItemStyle}                       key={11}><Popup content={this.renderPopupContent(LocalizedStrings.Users, LocalizedStrings.UsersText)} trigger={<Icon name='users'/>}/></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><UserTable small={this.isSmall}/></Tab.Pane> })
        if(workspace.Navigation.Worksheets)    this.panes.push(     { menuItem: <Menu.Item style={this.menuItemStyle}                       key={14}><Popup content={this.renderPopupContent(LocalizedStrings.Worksheets, LocalizedStrings.WorksheetsText)} trigger={<Icon name='file outline'/>}/></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><WorksheetTable small={this.isSmall}/></Tab.Pane> })
        if(workspace.Navigation.Tasks)    this.panes.push(     { menuItem: <Menu.Item style={this.menuItemStyle}                       key={15}><Popup content={this.renderPopupContent(LocalizedStrings.Tasks, LocalizedStrings.TasksText)} trigger={<Icon name='tasks'/>}/></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><UserTaskOverview small={this.isSmall}/></Tab.Pane> })
        if(workspace.Navigation.SavedViews)    this.panes.push(     { menuItem: <Menu.Item style={this.menuItemStyle}                       key={10}><Popup content={this.renderPopupContent(LocalizedStrings.SavedViews, LocalizedStrings.SavedViewsText)} trigger={<Icon name='save'/>}/></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><SavedViewTable small={this.isSmall}/></Tab.Pane> })
        if(workspace.Navigation.ColorCodes)    this.panes.push(     { menuItem: <Menu.Item style={this.menuItemStyle}                       key={4}><Popup  content={this.renderPopupContent(LocalizedStrings.ColorCodes, LocalizedStrings.ColorCodesText)} trigger={<Icon name='tint' />} /></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><ColorCodeTable small={this.isSmall} /></Tab.Pane>})
        if(workspace.Navigation.Departments)    this.panes.push(     { menuItem: <Menu.Item style={this.menuItemStyle}                       key={16}><Popup content={this.renderPopupContent(LocalizedStrings.Departments, LocalizedStrings.DepartmentsText)} trigger={<Icon name='building' />} /></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><DepartmentTable small={this.isSmall} /></Tab.Pane>})
        if(workspace.Navigation.Cases)    this.panes.push(     { menuItem: <Menu.Item style={this.menuItemStyle}                       key={7}><Popup  content={this.renderPopupContent(LocalizedStrings.Cases, LocalizedStrings.CasesText)} trigger={<Icon name='box'/>}/></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><CaseTable small={this.isSmall}/></Tab.Pane> })
        if(workspace.Navigation.Racks)    this.panes.push(     { menuItem: <Menu.Item style={this.menuItemStyle}                       key={12}><Popup content={this.renderPopupContent(LocalizedStrings.Racks, LocalizedStrings.RacksText)} trigger={<Icon name='th'/>}/></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><RackTable small={this.isSmall}/></Tab.Pane> })
        if(workspace.Navigation.Containers)    this.panes.push(     { menuItem: <Menu.Item style={this.menuItemStyle}                       key={6}><Popup  content={this.renderPopupContent(LocalizedStrings.Containers, LocalizedStrings.ContainersText)} trigger={<Icon name='truck'/>}/></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><ContainerTable small={this.isSmall}/></Tab.Pane> })
        if(workspace.Navigation.Errors)    this.panes.push(     { menuItem: <Menu.Item style={this.menuItemStyle}                       key={17}><Popup content={this.renderPopupContent(LocalizedStrings.Errors, LocalizedStrings.ErrorsText)} trigger={<Icon name='rain'/>}/></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><DrawingErrorTable small={this.isSmall}/></Tab.Pane> })
        if(workspace.Navigation.LoadGroups)    this.panes.push(     { menuItem: <Menu.Item style={this.menuItemStyle}                       key={8}><Popup  content={this.renderPopupContent(LocalizedStrings.LoadGroups, LocalizedStrings.LoadGroupsText)} trigger={<Icon name='law'/>}/></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><LoadGroupTable small={this.isSmall}/></Tab.Pane> })
        if(workspace.Navigation.LoadCombinations)    this.panes.push(     { menuItem: <Menu.Item style={{...this.menuItemStyle}} key={18}><Popup  content={this.renderPopupContent(LocalizedStrings.LoadCombinations, LocalizedStrings.LoadCombinationsText)} trigger={<Icon name='lab'/>}/></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><LoadCombinationTable small={this.isSmall}/></Tab.Pane> })
        if(workspace.Navigation.InfluenceLines)    this.panes.push(     { menuItem: <Menu.Item style={{...this.menuItemStyle, paddingRight: 5}} key={18}><Popup  content={this.renderPopupContent(LocalizedStrings.InfluenceLines, LocalizedStrings.InfluenceLines)} trigger={<Icon name='map pin'/>}/></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><InfluenceLineDisplay /></Tab.Pane> })

        
        this.state = 
        {
            Results: [],
            Active: {}
        }
    }

    componentDidMount = () => 
    {
        this.setupCallbacks();

        if(this.props.node) { this.props.node.setEventListener("close", (p) => {  this.props.onClose() }) }

        if (globalCallbacks.refreshStructuralCalculationListNavigation) {globalCallbacks.refreshStructuralCalculationListNavigation()}
        if (globalCallbacks.ShowProveTable) {globalCallbacks.ShowProveTable()}

    }

    render() 
    {
        this.isSmall = false;
        if (this.props.rect)
        {
            this.isSmall = this.props.rect.width < NAVIGATION_MIN_WIDTH
        }   

        let workloadHoist           = 0
        let workloadTruss           = 0

        let workloadTrussKeyed = {}

        this.state.Results.forEach(e=>
        {
            if     (e.Type ===  0) { workloadHoist = Math.max(e.Workload, workloadHoist)}
            else if(e.Type ===  1) { workloadHoist = Math.max(e.Workload, workloadHoist)}
            else if(e.Type ===  2) { workloadHoist = Math.max(e.Workload, workloadHoist)}
            else if(e.Type ===  3) { workloadHoist = Math.max(e.Workload, workloadHoist)}
            else if(e.Type ===  4) 
            { 
                workloadTruss = Math.max(e.Workload, workloadTruss)
                if( ! workloadTrussKeyed[e.Key])
                {
                    workloadTrussKeyed[e.Key] = e.Workload;
                }
                else
                {
                    workloadTrussKeyed[e.Key] = Math.max(workloadTrussKeyed[e.Key], e.Workload);
                }
            }
        })

        workloadHoist *= 100
        workloadTruss *= 100
        
        let panes = this.panes

        if(this.state.ShowProveTable)
        {
            panes = [...this.panes, { menuItem: <Menu.Item style={{...this.menuItemStyle, paddingRight: 5}} key={19}><Popup  content={this.renderPopupContent(LocalizedStrings.StructuralChecks, LocalizedStrings.StructuralChecksText)} trigger={<Icon color='red' name='tasks'/>}/></Menu.Item>, render: () => <Tab.Pane as={Container} style={{width: "100%"}}><StructuralChecks small={this.isSmall}/></Tab.Pane> }]
        }

        return (
            <div className="Navigation" style={{padding: "10px 10px 10px 10px"}}>
                <div style={{display: "flex", justifyContent:"space-between"}}>
                    <Header   style={{width: "15%"}} as="h5"> {LocalizedStrings.Header}</Header>
                    
                    <Popup  content={LocalizedStrings.WorkloadSupports} trigger={<Icon  color='blue'  style={{margin:"0.2rem 0 0 0.2rem"}} link size='small' name="chain"/>}/>
                    <Progress style={{width: "36%", height: "1.5rem", margin: "-0.25rem  0", fontSize:"0.85rem"}} progress percent={Math.ceil(workloadHoist)} success={this.state.Active.LowWorkload > workloadHoist} warning={this.state.Active.LowWorkload < workloadHoist} error = {this.state.Active.OverloadWorkload < workloadHoist} />
                    
                    <Popup  content={this.renderPopupContentTruss(workloadTrussKeyed)} trigger={<Icon color='green' style={{margin:"0.2rem 0 0 0.2rem"}} link size='small' name="pallet"/>}/>
                    <Progress style={{width: "36%", height: "1.5rem", float:"right", margin: "-0.25rem 0", fontSize:"0.85rem"}} progress percent={Math.ceil(workloadTruss)} success={this.state.Active.LowWorkload > workloadTruss} warning={this.state.Active.LowWorkload < workloadTruss} error = {this.state.Active.OverloadWorkload < workloadTruss}/>
                </div> 
        
                <Tab className="nav-tab" panes={panes} menu={{ text: true, style: {overflowX: "auto", marginTop:"-0.5rem"} }}/>
            </div>
        );
    }

    renderPopupContentTruss(workloadTrussKeyed)
    {
        return<div style={{minWidth: "20em"}}>
        <b>{LocalizedStrings.WorkloadStructure}</b>
        <br/>
        {Object.keys(workloadTrussKeyed).map((e)=> <div key={e}><Divider/><i>{e}</i><Progress  style={{width: "100%", height: "1.5rem", margin: "-0.25rem 0", fontSize:"0.85rem"}} progress percent={Math.ceil(workloadTrussKeyed[e] * 100)} success={this.state.Active.LowWorkload > workloadTrussKeyed[e] * 100} warning={this.state.Active.LowWorkload < workloadTrussKeyed[e] * 100} error = {this.state.Active.OverloadWorkload < workloadTrussKeyed[e] * 100}/><br/></div>)}
        </div>
    }


    renderPopupContent = (header, text) =>
    {
        return(
            <>
            <Header>
            {header}
            </Header>
            {text}
            </>
        )
    }

    setupCallbacks()
    {
     globalCallbacks.refreshStructuralCalculationListNavigation = async () => 
     {
         let res     = await window.LR_GetStructuralResult()
         let active  = await window.LR_GetActiveLoadCombination()
         this.setState({Results: res, Active: active})
     }

     globalCallbacks.ShowProveTable = async () => 
     {
        let drawingSettings     = await window.LR_GetDrawingSettings()
        this.setState({ShowProveTable: drawingSettings.SigningJobId !== ""})
     }
    }

}

export default NavigationContainer;

