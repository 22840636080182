import React, { useEffect, useMemo, useState } from "react";
import Editor from "react-simple-code-editor";
import { Checkbox, Divider, Form, Icon, Label, Message, Step } from "semantic-ui-react";
import LocalizedStrings from "../../../localization/GroupByComponent";
import { CUSTOM_CELL_FUNCTION_TYPE, DYNAMIC_ITEM_TYPE } from "../../../util/defines";
import LRModal from "../../Basics/BasicModal";
import { deepAssign } from "../../MergeView/MergeView";
import { MultiFieldSearch, PropertyListItemInterface, SingleFieldSearch } from "./FieldSearch";
import { lrSortPropInterface, lrWorksheetGroupingInterface } from "../../../util/callbackTypes";

const groupBySt = "%%__GROUP_BY_VALUE__%%"
const dividerSt = "%%__DIVIDER__%%"
const colorSt = "%%__COLOR()__%%"

function ChooseDynamicItem(this: unknown, props: {
    chosen: DYNAMIC_ITEM_TYPE,
    isUUIDType: boolean,
    onChosenItemsChanged: (d: DYNAMIC_ITEM_TYPE) => void
}) {
    const [retState, setRetState] = useState(props.chosen)
    useEffect(() => setRetState(props.chosen), [props.chosen])

    const onChangeVal = (t: DYNAMIC_ITEM_TYPE) => {
        setRetState(t)
        props.onChosenItemsChanged(t)
    }

    return <>
        <Message success>
            <Message.Header>{LocalizedStrings.GroupBySteps1Header}</Message.Header>
            <Message.Content>{LocalizedStrings.GroupBySteps1Text}</Message.Content>
        </Message>
        <Form.Group>
            <Form.Radio
                label={LocalizedStrings.DynamicObjectSelectionNone}
                checked={retState === DYNAMIC_ITEM_TYPE.NO_DYNAMIC_ITEM}
                onChange={() => onChangeVal(DYNAMIC_ITEM_TYPE.NO_DYNAMIC_ITEM)}
            />
            <Form.Radio
                label={LocalizedStrings.DynamicObjectSelectionFirst}
                checked={retState === DYNAMIC_ITEM_TYPE.FIRST_ITEM_IN_GROUP}
                onChange={() => onChangeVal(DYNAMIC_ITEM_TYPE.FIRST_ITEM_IN_GROUP)}
            />
            <Form.Radio
                label={LocalizedStrings.DynamicObjectSelectionConcat}
                checked={retState === DYNAMIC_ITEM_TYPE.CONCAT_ALL_ITEMS}
                onChange={() => onChangeVal(DYNAMIC_ITEM_TYPE.CONCAT_ALL_ITEMS)}
            />
            <Form.Radio
                label={LocalizedStrings.DynamicObjectSelectionFromID}
                checked={retState === DYNAMIC_ITEM_TYPE.GET_FROM_ID}
                onChange={() => onChangeVal(DYNAMIC_ITEM_TYPE.GET_FROM_ID)}
                disabled={!props.isUUIDType}
                data-tooltip={!props.isUUIDType ? LocalizedStrings.DynamicObjectSelectionFromID_NoTooltip : undefined}
            />
        </Form.Group>
    </>
}

function ChooseSortingMode({
    sortedBy,
    onChange,
    propertyList,
    itemType
}: {
    sortedBy: lrSortPropInterface,
    propertyList: PropertyListItemInterface[],
    onChange: (data: lrSortPropInterface) => void, 
    itemType: DYNAMIC_ITEM_TYPE
}) {
    let isNone = sortedBy.PropertyName === groupBySt
    let isDisabled = itemType === DYNAMIC_ITEM_TYPE.NO_DYNAMIC_ITEM || itemType === DYNAMIC_ITEM_TYPE.CONCAT_ALL_ITEMS

    return <>
        <Message success>
            <Message.Header>{LocalizedStrings.GroupBySteps5Header}</Message.Header>
            <Message.Content>{LocalizedStrings.GroupBySteps5Text}</Message.Content>
        </Message>

        <Form.Group>
            <Form.Radio
                label={LocalizedStrings.GroupBySortAscending}
                checked={sortedBy.Ascending}
                onChange={() => {
                    onChange({
                        ...sortedBy,
                        Ascending: true
                    })
                }}
            />
            <Form.Radio
                label={LocalizedStrings.GroupBySortDescending}
                checked={!sortedBy.Ascending}
                onChange={() => {
                    onChange({
                        ...sortedBy,
                        Ascending: false
                    })
                }}
            />
        </Form.Group>

        <Divider/>

        <div data-tooltip={isDisabled ? LocalizedStrings.GroupBySteps5NotPossible : undefined} style={{ height: "calc(100% - 200px)", display: "flex", flexDirection: "column" }}>

            <Form.Group>
                <Form.Radio
                    label={LocalizedStrings.GroupBySortUseGrouping}
                    checked={isNone}
                    onChange={() => {
                        onChange({
                            ...sortedBy,
                            PropertyName: groupBySt
                        })
                    }}
                    disabled={isDisabled}
                />
                <Form.Radio
                    label={LocalizedStrings.GroupBySortUseCustom}
                    checked={!isNone}
                    onChange={() => {
                        onChange({
                            ...sortedBy,
                            PropertyName: "Name"
                        })
                    }}
                    disabled={isDisabled}
                />
            </Form.Group>

            <SingleFieldSearch 
                style={{ maxHeight: "auto"}} 
                propertyList={propertyList} 
                chosenItem={propertyList.find(i => i.PropertyName === sortedBy.PropertyName)} 
                disabled={isNone || isDisabled}
                onItemChange={it => {
                    onChange({
                        ...sortedBy,
                        PropertyName: it.PropertyName
                    })
                }} 
            />
        </div>
    </>
}

function shortcutInterrupt(e: KeyboardEvent) {
    e.stopPropagation()
    e.stopImmediatePropagation()
}

function ChooseModal(this: unknown, props: {
    open: boolean,
    propertyList: PropertyListItemInterface[],
    groupingProp: lrWorksheetGroupingInterface,
    onClose: (item?: lrWorksheetGroupingInterface) => void
}) {
    const [activeTab, setActiveTab] = useState(0)

    const [dynamicProperties, setDynamicProperties] = useState([])

    const defVal: lrWorksheetGroupingInterface = {
        DynamicItemType: DYNAMIC_ITEM_TYPE.NO_DYNAMIC_ITEM,
        GroupByProperty: "",
        PropertiesString: groupBySt,
        PropertiesCellList: [],
        SortedBy: {
            Ascending: true,
            PropertyName: groupBySt
        }
    }
    let [modifiedProp, setModifiedProp] = useState<lrWorksheetGroupingInterface>(deepAssign(props.groupingProp ?? defVal))
    let GroupByPropItem = useMemo(() => (modifiedProp ? props.propertyList.find(i => i.PropertyName === modifiedProp.GroupByProperty) : undefined), [modifiedProp.GroupByProperty])

    useEffect(() => setModifiedProp(nValue => {
        let evList = ["keydown", "keyup", "copy", "paste", "cut",]
        if (props.open) {
            nValue = deepAssign(props.groupingProp ?? defVal)
            let list = []
            for (let i of nValue.PropertiesString.match(/(%%([^%]+)%%)/g)) {
                let sl = i.slice(2, -2)
                list.push(props.propertyList.find(i => i.PropertyName === sl))
            }
            setDynamicProperties(list.filter(i => !!i))
            setActiveTab(0)
            for (let i of evList) window.addEventListener(i, shortcutInterrupt, true)
        } else {
            for (let i of evList) window.removeEventListener(i, shortcutInterrupt, true)
        }

        return nValue
    }), [props.open])

    const closeModal = (doSave = false) => {
        props.onClose(doSave ? modifiedProp : undefined)
    }

    const onItemChange = (item: PropertyListItemInterface) => {
        if (item?.PropertyName) {
            setModifiedProp(i => ({
                ...i,
                GroupByProperty: item?.PropertyName ?? "",
                DynamicItemType: (item.CustomCellFunctionType !== CUSTOM_CELL_FUNCTION_TYPE.OBJECT_FIELD && modifiedProp.DynamicItemType === DYNAMIC_ITEM_TYPE.GET_FROM_ID) ? DYNAMIC_ITEM_TYPE.NO_DYNAMIC_ITEM : i.DynamicItemType
            }))
        }
    }

    const onDynamicItemTypeChange = (type: DYNAMIC_ITEM_TYPE) => setModifiedProp(old => {
        let oldSort = old.SortedBy
        if(type == DYNAMIC_ITEM_TYPE.NO_DYNAMIC_ITEM || type == DYNAMIC_ITEM_TYPE.CONCAT_ALL_ITEMS)
        {
            oldSort.PropertyName = groupBySt
        }

        return {
            ...old,
            SortedBy: oldSort,
            DynamicItemType: type
        }
    })

    const toggleDynamicProperties = (propToToggle: PropertyListItemInterface | PropertyListItemInterface[], checked: boolean, e: any) => setModifiedProp(mProp => {
        let newEnabledProperties = dynamicProperties
        let stP = mProp.PropertiesString

        const setSingleProperty = (prop: PropertyListItemInterface) => {
            let extraPropertyIndex = newEnabledProperties.findIndex(p => (p.ArrayName || undefined) === (prop.ArrayName || undefined) && p.PropertyName === prop.PropertyName)

            if (e && e.altKey && !Array.isArray(propToToggle)) {
                newEnabledProperties = [prop]
                stP = stP.replace(RegExp(`(^(?!%%${prop.PropertyName}%%)(?!${groupBySt})(?!${dividerSt})(%%([^%]+)%%).*$)`), "")
            }
            else if (extraPropertyIndex === -1 && checked) {
                newEnabledProperties.push(prop)
                stP += ` %%${prop.PropertyName}%%`
            }
            else if (extraPropertyIndex !== -1 && !checked) {
                newEnabledProperties.splice(extraPropertyIndex, 1)
                stP = stP.replace(`%%${prop.PropertyName}%%`, "")
            }
        }


        if (Array.isArray(propToToggle)) {
            for (let i of propToToggle) {
                setSingleProperty(i)
            }
        } else {
            setSingleProperty(propToToggle)
        }


        setDynamicProperties(newEnabledProperties)
        stP = stP.trimEnd()
        return {
            ...mProp,
            PropertiesString: stP
        }
    })

    const setStaticProperites = (str: string) => setModifiedProp(mProp => {
        return {
            ...mProp,
            PropertiesString: str
        }
    })

    const highlightFun = (str: string) => {
        str = str.replace(/(?!%%__DIVIDER__%%)(?!%%__GROUP_BY_VALUE__%%)(%%([^%^\s]+)%%)/g, '<font color="#9900FF">$&</font>')
        str = str.replace(/(%%__DIVIDER__%%)/g, '<font color="#1900FF">$&</font>')
        str = str.replace(/(%%__GROUP_BY_VALUE__%%)/g, '<font color="#1900FF">$&</font>')
        str = str.replace(/(%%__COLOR\(([^%]+)\)__%%)/g, '<font color="#1900FF">$&</font>')
        return str
    }
    return <LRModal
        open={props.open}
        size="small"
        title={LocalizedStrings.AddGroupingProp}
        onCancelClick={() => closeModal()}
        okDisabled={!modifiedProp.GroupByProperty.length}
        onOkClick={() => closeModal(true)}
        scrolling={true}
    >
        <div style={{ height: "50vh" }}>
            <Step.Group size='mini' attached="top" fluid>
                <Step active={activeTab === 0} onClick={() => setActiveTab(0)} style={{ cursor: "pointer" }}>
                    <Step.Content>
                        <Step.Title>{LocalizedStrings.GroupBySteps1Title}</Step.Title>
                    </Step.Content>
                </Step>

                <Step active={activeTab === 1} onClick={() => setActiveTab(1)} style={{ cursor: "pointer" }} disabled={!modifiedProp.GroupByProperty.length}>
                    <Step.Content>
                        <Step.Title>{LocalizedStrings.GroupBySteps2Title}</Step.Title>
                    </Step.Content>
                </Step>
                <Step active={activeTab === 2} onClick={() => setActiveTab(2)} style={{ cursor: "pointer" }} disabled={modifiedProp.DynamicItemType === DYNAMIC_ITEM_TYPE.NO_DYNAMIC_ITEM}>
                    <Step.Content>
                        <Step.Title>{LocalizedStrings.GroupBySteps3Title}</Step.Title>
                    </Step.Content>
                </Step>
                <Step active={activeTab === 3} onClick={() => setActiveTab(3)} style={{ cursor: "pointer" }} disabled={!modifiedProp.GroupByProperty.length}>
                    <Step.Content>
                        <Step.Title>{LocalizedStrings.GroupBySteps4Title}</Step.Title>
                    </Step.Content>
                </Step>
                <Step active={activeTab === 4} onClick={() => setActiveTab(4)} style={{ cursor: "pointer" }} disabled={!modifiedProp.GroupByProperty.length}>
                    <Step.Content>
                        <Step.Title>{LocalizedStrings.GroupBySteps5Title}</Step.Title>
                    </Step.Content>
                </Step>
            </Step.Group>


            {activeTab === 0 && <>
                <div style={{ height: "calc(100% - 40px)", display: "flex", flexDirection: "column" }}>
                    <div style={{ marginTop: 14 }}>
                        <Message success>
                            <Message.Header>{LocalizedStrings.GroupBySteps2Header}</Message.Header>
                            <Message.Content>{LocalizedStrings.GroupBySteps2Text}</Message.Content>
                        </Message>
                    </div>
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <Message size="mini">
                            <Message.Header>{LocalizedStrings.ArrayGroupingNotPossibleHeader}</Message.Header>
                            <Message.Content>{LocalizedStrings.ArrayGroupingNotPossibleText}</Message.Content>
                        </Message>
                    </div>
                    <SingleFieldSearch style={{ maxHeight: "auto" }} propertyList={props.propertyList} chosenItem={GroupByPropItem} onItemChange={onItemChange} showIsObjectProperty />
                </div>
            </>}
            {activeTab === 1 && <>
                <ChooseDynamicItem chosen={modifiedProp.DynamicItemType} onChosenItemsChanged={onDynamicItemTypeChange} isUUIDType={GroupByPropItem?.CustomCellFunctionType === CUSTOM_CELL_FUNCTION_TYPE.OBJECT_FIELD} />
            </>}
            {activeTab === 2 && <>
                <div style={{ height: "calc(100% - 40px)", display: "flex", flexDirection: "column" }}>
                    <div style={{ marginTop: 14, marginBottom: 10 }}>
                        <Message success>
                            <Message.Header>{LocalizedStrings.GroupBySteps3Header}</Message.Header>
                            <Message.Content>{LocalizedStrings.GroupBySteps3Text}</Message.Content>
                        </Message>
                    </div>
                    <MultiFieldSearch style={{ maxHeight: "auto" }} propertyList={props.propertyList} enabledProperties={dynamicProperties} toggleShowProp={toggleDynamicProperties} />
                </div>
            </>}
            {activeTab === 3 && <>
                <Message success>
                    <Message.Header>{LocalizedStrings.GroupBySteps4Header}</Message.Header>
                    <Message.Content>- {LocalizedStrings.GroupBySteps4TextA}</Message.Content>
                    <Message.Content>- {LocalizedStrings.GroupBySteps4TextB}</Message.Content>
                    {/*@ts-ignore*/}
                    <Message.Content>- {LocalizedStrings.GroupBySteps4TextC_A} <font color="#1900FF">{groupBySt}</font>{LocalizedStrings.GroupBySteps4TextC_B}</Message.Content>
                    {/*@ts-ignore*/}
                    <Message.Content>- {LocalizedStrings.GroupBySteps4TextD_A} <font color="#1900FF">{dividerSt}</font>{LocalizedStrings.GroupBySteps4TextD_B}</Message.Content>
                    {/*@ts-ignore*/}
                    <Message.Content>- {LocalizedStrings.GroupBySteps4TextE_A} <font color="#1900FF">{colorSt}</font>{LocalizedStrings.GroupBySteps4TextE_B}</Message.Content>
                </Message>
                <Editor
                    value={modifiedProp.PropertiesString}
                    onValueChange={val => setStaticProperites(val)}
                    highlight={highlightFun}
                    padding={10}
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12,
                        minHeight: 200,
                        color: "rgba(0,0,0,.95)",
                        borderColor: "#85b7d9",
                        borderRadius: "0.28571429rem",
                        border: "solid gray",
                        borderWidth: "1px",
                        marginTop: 10
                    }}
                />
            </>}
            {activeTab === 4 &&
                <ChooseSortingMode
                    propertyList={props.propertyList}
                    sortedBy={modifiedProp.SortedBy}
                    onChange={val => setModifiedProp(i => ({ ...i, SortedBy: val }))}
                    itemType={modifiedProp.DynamicItemType}
                />
            }

        </div>

    </LRModal>

}

export default function (this: unknown, props: {
    propertyList: PropertyListItemInterface[]
    groupingProp?: lrWorksheetGroupingInterface,
    onChangeGroupByProperty: (val: lrWorksheetGroupingInterface) => void
    onCopy?: (val: lrWorksheetGroupingInterface) => void
    onDelete?: () => void
}) {
    let propertyList = useMemo(() => props.propertyList.filter(i => !i.ArrayName), [props.propertyList])
    let [modalOpen, setModalOpen] = useState(false)

    return (
        <>
            <ChooseModal
                open={modalOpen}
                propertyList={propertyList}
                groupingProp={props.groupingProp}
                onClose={(item) => {
                    if (item) { props.onChangeGroupByProperty(item) }
                    setModalOpen(false)
                }}
            />
            {
                props.groupingProp ?
                    <Label
                        style={{ width: "100%", marginBottom: "5px", marginLeft: 0, cursor: "pointer", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
                        onClick={() => setModalOpen(!modalOpen)}
                    >
                        <div>
                            {propertyList.find(i => i.PropertyName === props.groupingProp.GroupByProperty).LocalizedName}
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                <Icon name='edit outline' />
                            </div>
                            <div onClick={(e) => {
                                e.stopPropagation()
                                props.onCopy?.(deepAssign(props.groupingProp))
                            }}>
                                <Icon name='copy outline' />
                            </div>
                            <div onClick={(e) => {
                                e.stopPropagation()
                                props.onDelete?.()
                            }}>
                                <Icon name='delete' />
                            </div>
                        </div>
                    </Label>
                    :
                    <Label style={{ width: "100%", marginBottom: "5px", marginLeft: 0, cursor: "pointer" }} onClick={() => setModalOpen(!modalOpen)}>
                        {LocalizedStrings.AddGroupingProp}
                    </Label>
            }
        </>
    )
}